import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  state: {
    cart: {
      id: null,
      lines: {
        nodes: [],
      },
      checkoutUrl: null,
      cost: {
        subtotalAmount: {},
        totalAmount: {},
        totalTaxAmount: {},
      },
      totalQuantity: 0,
    },
    optionColors: [],
  },
  mutations,
  getters,
  actions,
};
