export default /* GraphQL */ `
  query ($handle: String!, $lang: LanguageCode!) @inContext(language: $lang) {
    menu(handle: $handle) {
      handle
      id
      items {
        id
        url
        type
        title
        items {
          id
          url
          type
          title
          items {
            id
            url
            type
            title
          }
        }
      }
    }
  }
`;
