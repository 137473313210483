const labels = {
  de: {
    back_cta: "Zurück",
    back_home_cta: "Zurück zur Startseite",
    close_cta: "Schließen",
    credits: "website von Giga Design Studio",
    enquiry_block_body:
      "Guten Tag%0D%0AIch interessiere mich für ein Schmuckstück.%0D%0AFreundliche Grüsse",
    enquiry_body:
      "Guten Tag%0D%0AIch interessiere mich für dieses Schmuckstück.%0D%0AFreundliche Grüsse",
    enquiry_cta: "Anfragen",
    info_cta: "Info",
    menu_cta: "Menu",
    not_found_text: "404 Seite nicht gefunden",
    price_vat_excluded: "(ohne MwSt)",
    price_vat_included: "(inkl. MwSt)",
    read_less_cta: "Lese weniger",
    read_more_cta: "Weiterlesen",
    related_title: "Aus der Sammlung",
    add_to_cart: "In den Warenkorb",
    cart_title: "Warenkorb",
    cart_remove: "Entfernen",
    price_from: "ab",
    cart_subtotal: "Zwischensumme",
    cart_shipping: "Versand",
    cart_shipping_text: "Wird an der Kasse berechnet",
    sold_out: "Ausverkauft",
    cart_note: "Notizen für die Bestellung hinzufügen",
    checkout_cta: "Zur Kasse gehen",
    cart_note_placeholder: "Hier Notiz einfügen...",
    cart_empty: "Ihr Warenkorb ist leer",
    cart_line_quantity: "Menge",
    enquiry_subject: "Anfrage für",
    addtocart_modal_title: "Vielen Dank!",
    addtocart_modal_text:
      "1 Artikel wurde erfolgreich zu Ihrem Warenkorb hinzugefügt",
    addtocart_modal_cta: "Warenkorb ansehen",
    addtocart_modal_cta_checkout: "Zur Kasse gehen",
    addtocart_modal_or: "oder",
    filters_clear: "Alle löschen",
    filters_title: "Filtern nach",
    filters_cta: "Filter",
    related_products_heading: "Verwandte Produkte",
    sizeguide_title: "Größentabelle",
    sizeguide_size: "Größe",
    sizeguide_diameter: "Innendurchmesser in mm",
    sizeguide_europe: "Europa",
    sizeguide_us: "US",
    sizeguide_uk: "UK"
  },
  en: {
    back_cta: "Back",
    back_home_cta: "Back to homepage",
    close_cta: "Close",
    credits: "website by Giga Design Studio",
    enquiry_block_body:
      "Hello%0D%0AI am interested in a jewellery.%0D%0AKind regards",
    enquiry_body:
      "Hello%0D%0AI am interested in this jewellery.%0D%0AKind regards",
    enquiry_cta: "Please enquire",
    info_cta: "Info",
    menu_cta: "Menu",
    not_found_text: "404 Page not found",
    price_vat_excluded: "(VAT excluded)",
    price_vat_included: "(VAT included)",
    read_less_cta: "Read less",
    read_more_cta: "Read more",
    related_title: "From the collection",
    add_to_cart: "Add to cart",
    cart_title: "Cart",
    cart_remove: "Remove",
    price_from: "from",
    cart_subtotal: "Subtotal",
    cart_shipping: "Shipping",
    cart_shipping_text: "Calculated at checkout",
    sold_out: "Sold out",
    cart_note: "Insert notes for the order",
    checkout_cta: "Proceed to checkout",
    cart_note_placeholder: "Insert note here...",
    cart_empty: "Your cart is empty",
    cart_line_quantity: "Quantity",
    enquiry_subject: "Enquiry for",
    addtocart_modal_title: "Thank you!",
    addtocart_modal_text: "1 Item has been successfully added to your cart",
    addtocart_modal_cta: "View cart",
    addtocart_modal_cta_checkout: "Go to checkout",
    addtocart_modal_or: "or",
    filters_clear: "Clear all",
    filters_title: "Filter by",
    filters_cta: "Filters",
    related_products_heading: "Related Products",
    sizeguide_title: "Sizeguide",
    sizeguide_size: "Size",
    sizeguide_diameter: "Internal Diameter in mm",
    sizeguide_europe: "Europe",
    sizeguide_us: "US",
    sizeguide_uk: "UK"
  },
};

labels.install = (vm, store) => {
  vm.prototype.$labels = () => labels[store.state.lang];
};

export default labels;
