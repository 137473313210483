var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.meta.hideHeader),expression:"!$route.meta.hideHeader"}],class:[
    'header',
    _vm.mobileMenu || _vm.mobileFilters ? 'header--mobile' : false,
    _vm.$route.name === 'Home' ||
    (_vm.currentPost ? _vm.currentPost.acf.text_color === 'white' : null)
      ? 'header--home'
      : false,
  ],on:{"click":function($event){_vm.mobileMenu
      ? _vm.toggleMobileMenu()
      : _vm.mobileFilters
      ? _vm.toggleMobileFilters()
      : false}}},[_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"typo--p--trimmed"},[_c('router-link',{staticClass:"logo",attrs:{"to":_vm.lang === _vm.defaultLang ? '/' : `/${_vm.lang}/`}},[_vm._v(" "+_vm._s(_vm.$store.state.wp.site.name)+" ")])],1),_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(false),expression:"false"}],staticClass:"placeholder typo--p--trimmed"},[_vm._v(" ")]),(_vm.$route.name !== 'Product')?_c('LanguageSelector'):_vm._e()],1),_c('button',{staticClass:"mobile-menu-button notranslate",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMobileMenu.apply(null, arguments)}}},[_c('div',{staticClass:"typo--p--trimmed",domProps:{"innerHTML":_vm._s(
        _vm.mobileFilters
          ? ''
          : _vm.mobileMenu
          ? _vm.$labels().close_cta
          : _vm.$labels().menu_cta
      )}})]),(_vm.$route.name === 'Product' && !_vm.$mq.isMobile)?_c('Link',{attrs:{"data":{
      title: _vm.$labels().back_cta,
      fn: _vm.goBack,
    },"theme":"plain"}}):(!_vm.mobileFilters)?_c('Menu',{attrs:{"theme":_vm.mobileMenu ? 'mobile' : ''}}):_vm._e(),(_vm.hasFilters && _vm.categories && (!_vm.$mq.isMobile || _vm.mobileFilters))?_c('div',{staticClass:"filters"},[_c('div',{staticClass:"filters-title typo--p--trimmed"},[_vm._v(" "+_vm._s(_vm.$labels().filters_title)+" ")]),_c('div',{staticClass:"filters-list"},[_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(false),expression:"false"}],staticClass:"link-placeholder typo--p--trimmed"},[_vm._v("   ")]),_vm._l((_vm.categories),function(item){return _c('Link',{key:item.id,class:{ 'filter-active': _vm.$relativeUrl(item.url) === _vm.$route.path },attrs:{"theme":"plain","data":{
          url: item.url,
          title: item.content,
          target: item.target,
        }}})}),(_vm.$route.path !== '/shop/')?_c('Link',{staticClass:"filters-clear-button",attrs:{"data":{ title: _vm.$labels().filters_clear, url: '/shop/' },"theme":"grey"}}):_vm._e()],2)]):_vm._e(),(_vm.hasFilters && _vm.categories && !_vm.mobileMenu)?_c('div',{staticClass:"filters-mobile-trigger"},[_c('div',{staticClass:"filters-title typo--p--trimmed",on:{"click":function($event){$event.stopPropagation();return _vm.toggleMobileFilters.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.filterMobileCta)+" ")])]):_vm._e(),(_vm.mobileMenu)?_c('div',{staticClass:"social"},_vm._l((_vm.social),function(item){return _c('Link',{key:item.id,attrs:{"data":{ ...item, target: '_blank' },"theme":"plain"}})}),1):_vm._e(),(!_vm.mobileFilters)?_c('LanguageSelector',{attrs:{"id":"language-selector--mobile"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }