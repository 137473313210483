export default /* GraphQL */ `
  query ($type: String!) {
    metaobjects(type: $type, first: 100) {
      nodes {
        fields {
          key
          value
        }
      }
    }
  }
`;