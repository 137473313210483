<template>
  <div v-if="related" class="related">
    <Grid column-gap="xs" row-gap="l" :col="{ default: 6, m: 2 }">
      <Title theme="uppercase" :value="$labels().related_products_heading" class="title" />
      <div v-for="(p, index) in related" :key="`${related.handle}-${index}`">
        <ThumbProduct
          :data="{ product: p }"
          theme="collection"
          class="related-thumb"
        />
      </div>
    </Grid>
  </div>
</template>

<script>
import { fetchProductRecommendations } from "../../api/shopify";
import ThumbProduct from "@/components/thumb/product";
import Title from "@/components/typo/title";

export default {
  name: "Price",
  components: {
    ThumbProduct,
    Title,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      related: null,
    };
  },
  methods: {
    async getProductRecommendations() {
      const params = {
        productId: this.id,
        intent: "RELATED",
        lang: this.$store.state.lang,
      };

      const {
        data: { data },
      } = await fetchProductRecommendations({ params });

      if (data) {
        this.related = data;
      }
    },
  },
  watch: {
    related: {
      handler: function (val) {
        if (val && val?.productRecommendations?.length) {
          this.related = val?.productRecommendations.slice(0, 5);
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.getProductRecommendations();
  },
};
</script>

<style lang="scss" scoped>
.related {
  margin: 0 var(--spacer-xs);

  @include mq(m) {
    margin: var(--spacer-xs) var(--spacer-xs) 0 var(--spacer-xs);
  }

  .title {
    display: none;

    @include mq(m) {
      display: grid;
    }
  }

  .grid > div:last-child {
    display: none;

    @include mq(m) {
      display: grid;
    }
  }
}
</style>
