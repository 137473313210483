<template>
  <div class="product-preview product-preview--three-center">
    <Media v-if="value.media_two.length > 0" :data="value.media_two[0]" />
    <div class="media placeholder">
      <Media v-if="value.media_two.length > 1" :data="value.media_two[1]" />
      <Thumb :data="{ product: value.product.shopify }" placeholder />
    </div>
    <Thumb theme="collection" :data="{ product: value.product.shopify }" />
  </div>
</template>

<script>
import Media from "@/components/media";
import Thumb from "@/components/thumb/product";

export default {
  components: {
    Media,
    Thumb,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.product-preview {
  &--three-center {
    gap: var(--column-gap-xs);
    grid-template-columns: repeat(var(--preview-columns), minmax(0, 1fr));
    align-items: end;

    @include mq(m) {
      --preview-columns: 12;
      row-gap: var(--row-gap-l);
    }

    > :first-child:not(.thumb) {
      grid-column: 1 / -1;
      transform: translateX(calc(-1 * var(--spacer-page)));
      width: calc(100% + 2 * var(--spacer-page));

      @include mq(m) {
        grid-column: 3 / 11;
        width: 100%;
        transform: initial;
      }
    }

    > :nth-child(2):not(.thumb) {
      @include mq(m) {
        grid-column: 7 / -1;
      }
    }

    > .thumb {
      grid-column-end: -1;
      grid-row-start: 3;
      margin-top: var(--row-gap-m);

      @include mq(m) {
        grid-row-start: 2;
        grid-column: span 3;
        margin-top: 0;
      }
    }

    .placeholder {
      .thumb {
        display: none;

        @include mq(m) {
          display: grid;
        }
      }
    }
  }
}
</style>
