<template>
  <header
    v-show="!$route.meta.hideHeader"
    :class="[
      'header',
      mobileMenu || mobileFilters ? 'header--mobile' : false,
      $route.name === 'Home' ||
      (currentPost ? currentPost.acf.text_color === 'white' : null)
        ? 'header--home'
        : false,
    ]"
    @click="
      mobileMenu
        ? toggleMobileMenu()
        : mobileFilters
        ? toggleMobileFilters()
        : false
    "
  >
    <div class="first-row">
      <div class="typo--p--trimmed">
        <router-link
          class="logo"
          :to="lang === defaultLang ? '/' : `/${lang}/`"
        >
          {{ $store.state.wp.site.name }}
        </router-link>
      </div>
      <div v-visible="false" class="placeholder typo--p--trimmed">&nbsp;</div>
      <LanguageSelector v-if="$route.name !== 'Product'" />
    </div>
    <button
      class="mobile-menu-button notranslate"
      @click.stop="toggleMobileMenu"
    >
      <div
        class="typo--p--trimmed"
        v-html="
          mobileFilters
            ? ''
            : mobileMenu
            ? $labels().close_cta
            : $labels().menu_cta
        "
      />
    </button>
    <Link
      v-if="$route.name === 'Product' && !$mq.isMobile"
      :data="{
        title: $labels().back_cta,
        fn: goBack,
      }"
      theme="plain"
    />
    <Menu v-else-if="!mobileFilters" :theme="mobileMenu ? 'mobile' : ''" />
    <div
      v-if="hasFilters && categories && (!$mq.isMobile || mobileFilters)"
      class="filters"
    >
      <div class="filters-title typo--p--trimmed">
        {{ $labels().filters_title }}
      </div>
      <div class="filters-list">
        <div v-visible="false" class="link-placeholder typo--p--trimmed">
          &nbsp;
        </div>
        <Link
          v-for="item in categories"
          :key="item.id"
          theme="plain"
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
          :class="{ 'filter-active': $relativeUrl(item.url) === $route.path }"
        />
        <Link
          :data="{ title: $labels().filters_clear, url: '/shop/' }"
          theme="grey"
          class="filters-clear-button"
          v-if="$route.path !== '/shop/'"
        />
      </div>
    </div>
    <div
      v-if="hasFilters && categories && !mobileMenu"
      class="filters-mobile-trigger"
    >
      <div
        class="filters-title typo--p--trimmed"
        @click.stop="toggleMobileFilters"
      >
        {{ filterMobileCta }}
      </div>
    </div>
    <div class="social" v-if="mobileMenu">
      <Link
        v-for="item in social"
        :key="item.id"
        :data="{ ...item, target: '_blank' }"
        theme="plain"
      />
    </div>
    <LanguageSelector id="language-selector--mobile" v-if="!mobileFilters" />
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/ui/menu";
// import WcMenu from '@/components/wc/wc-menu';
import LanguageSelector from "@/components/ui/language-selector";
import Link from "@/components/typo/link";

export default {
  name: "Header",
  components: {
    Menu,
    // WcMenu,
    LanguageSelector,
    Link,
  },
  data() {
    return {
      mobileMenu: false,
      mobileFilters: false,
    };
  },
  computed: {
    ...mapGetters([
      "menu",
      "defaultLang",
      "lang",
      "currentPost",
      "invertHeader",
      "from",
    ]),
    social() {
      const nav = this.menu("social");
      return nav ? nav.items : null;
    },
    categories() {
      const nav = this.menu("categories");
      return nav ? nav.items : null;
    },
    hasFilters() {
      return (
        this.$route.params.slug === "shop" ||
        this.categories
          .map((el) => this.$relativeUrl(el.url))
          .includes(this.$route.path)
      );
    },
    filterMobileCta() {
      return this.mobileFilters
        ? this.$labels().close_cta
        : this.$route.params.slug == "shop"
        ? this.$labels().filters_cta
        : `${this.$labels().filters_cta} (1)`;
    },
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    toggleMobileFilters() {
      this.mobileFilters = !this.mobileFilters;
    },
    goBack() {
      if (!this.from) {
        this.$router.push({
          path:
            this.lang === this.defaultLang
              ? "/products"
              : `/${this.lang}/products`,
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  --header-columns: 2;

  align-items: start;
  color: var(--header-color);
  display: grid;
  gap: var(--column-gap-xs);
  grid-template-columns: repeat(var(--header-columns), minmax(0, 1fr));
  justify-items: start;
  padding: var(--spacer-xs);
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;

  @include mq(m) {
    --header-columns: 6;
  }

  .menu {
    display: none;

    @include mq(m) {
      display: grid;
      grid-column: 2 / span 1;
    }
  }

  #language-selector--mobile {
    display: none;
  }

  .social {
    display: none;
  }

  &--home {
    --header-color: var(--white);
  }

  &--mobile {
    --header-color: var(--black);

    background: var(--white);
    height: calc(var(--vh) * 100);
    grid-template-rows: repeat(2, minmax(0, max-content));
    row-gap: var(--row-gap-l);

    .menu {
      display: grid;
      grid-column: 2;
    }

    .social {
      align-self: end;
      display: grid;
      grid-auto-flow: column;
      gap: var(--column-gap-xs);
    }

    #language-selector--mobile {
      align-self: end;
      display: grid;
    }
  }

  .first-row {
    display: grid;
    gap: var(--row-gap-xs);
  }

  .logo {
    text-decoration: none;
    @include ellipsis;
  }

  .language-selector {
    display: none;

    @include mq(m) {
      display: grid;
    }
  }

  .mobile-menu-button {
    @include mq(m) {
      display: none;
    }
  }

  .placeholder {
    display: none;

    @include mq(m) {
      display: initial;
    }
  }

  .filters {
    grid-column: 2;
    display: grid;
    gap: var(--column-gap-s);

    @include mq(m) {
      grid-column: 3 / span 1;
      gap: var(--column-gap-xs);
    }

    &-title {
      color: var(--grey);
    }

    &-list {
      display: grid;
      grid-auto-flow: row;
      gap: var(--column-gap-s);

      @include mq(m) {
        gap: var(--row-gap-xs);
      }
    }

    &-clear-button {
      color: var(--grey);
      @media (hover: hover) {
        &:hover {
          color: var(--black);
        }
      }
    }

    .filter-active {
      font-style: italic;
    }
  }
}

.filters-mobile-trigger {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacer-xs);
  position: fixed;
  bottom: var(--spacer-xs);
  pointer-events: none;

  .filters-title {
    grid-column: 2;
    pointer-events: all;
    color: var(--black);
  }

  @include mq(m) {
    display: none;
  }
}
</style>
