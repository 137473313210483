var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"product"},[_c('div',{staticClass:"product-content"},[(_vm.productGallery?.length > 1 && !_vm.$mq.isMobile)?_c('div',{staticClass:"product-gallery-desktop-pagination"},[_c('div',{staticClass:"pagination-container"},_vm._l((_vm.productGallery),function(media,index){return _c('div',{class:['pagination-button', { active: index == _vm.activeImage }],attrs:{"data-index":index},on:{"click":function($event){return _vm.setActiveMediaIndex(index)}}})}),0)]):_vm._e(),(_vm.productGallery?.length && _vm.$mq.isMobile)?_c('Gallery',{attrs:{"data":{ value: { gallery: _vm.productGallery }, options: _vm.productOptions },"variantActiveIndex":_vm.activeIndex,"currentSelectedVariant":_vm.currentSelectedVariant,"theme":'product'}}):(_vm.productGallery?.length)?_c('div',{staticClass:"product-gallery-desktop"},_vm._l((_vm.productGallery),function(media,index){return _c('div',{key:`image${index}`},[(media.mediaContentType === 'IMAGE')?_c('Figure',{ref:`image${index}`,refInFor:true,class:['product-image', { active: index == _vm.activeImage }],attrs:{"data":{
            value: {
              src: _vm.parsedData(media.image),
            },
            settings: {},
          },"theme":"product","data-index":index,"data-src":media.image?.src,"lazyload":false,"disable-ratio":""}}):(media.mediaContentType === 'VIDEO')?_c('Video',{ref:`image${index}`,refInFor:true,attrs:{"data":media,"src":media?.sources?.[0]?.url,"theme":"product","index":index}}):_vm._e()],1)}),0):_vm._e(),_c('div',{staticClass:"product-information"},[_c('div',{staticClass:"first-box"},[(_vm.product?.title)?_c('Title',{staticClass:"product-title",attrs:{"value":_vm.product?.title,"typo":"h3"}}):_vm._e(),_c('Price',{staticClass:"product-price",attrs:{"theme":"inline","price":_vm.currentSelectedVariant?.price,"compareAtPrice":_vm.currentSelectedVariant?.compareAtPrice}}),(_vm.isEnquiry)?_c('a',{staticClass:"enquire-button-mobile typo--h3--trimmed",attrs:{"href":_vm.url}},[_vm._v(" "+_vm._s(_vm.$labels().enquiry_cta)+" ")]):_c('button',{class:[
            'addtocart-button-mobile',
            'typo--h3--trimmed',
            { disabled: !_vm.currentSelectedVariant?.availableForSale },
          ],on:{"click":_vm.addToCart}},[_vm._v(" "+_vm._s(_vm.addToCartLabel)+" ")]),(_vm.product?.descriptionHtml)?_c('Richtext',{staticClass:"product-description",attrs:{"theme":"italic","typo":"h3","value":_vm.product.descriptionHtml}}):_vm._e(),(
            _vm.product?.options?.length &&
            _vm.product.variants.nodes[0].title !== 'Default Title'
          )?_c('div',{staticClass:"product-selector"},_vm._l((_vm.product.options),function(option,index){return _c('div',{key:option.name,class:[
              'option-container',
              {
                'size-selector-container':
                  option.name === 'Size' ||
                  option.name === 'size' ||
                  option.name === 'Size' ||
                  option.name === 'Größe' ||
                  option.name === 'größe',
              },
            ]},[_c('div',{staticClass:"typo--h3"},[_vm._v(_vm._s(option.name))]),(
                option.name === 'Size' ||
                option.name === 'size' ||
                option.name === 'Größe' ||
                option.name === 'größe' || 
                option.name === 'Ringgröße' ||
                option.name === 'Ringgrösse'
              )?_c('div',[_c('select',{staticClass:"size-selector",on:{"change":function($event){return _vm.setCurrentVariant(option.name, $event)}}},_vm._l((option.values),function(value){return _c('option',{key:`${option.name.replaceAll(
                    ' ',
                    ''
                  )}-${value.replaceAll(' ', '')}`},[_vm._v(" "+_vm._s(value)+" ")])}),0),(_vm.tableData.length)?_c('AccordionWrapper',{staticClass:"product-sizeguide"},[_c('AccordionItem',[_c('template',{slot:"accordion-trigger"},[_c('div',{staticClass:"trigger typo--h3"},[_vm._v(_vm._s(_vm.$labels().sizeguide_title))])]),_c('template',{slot:"accordion-content"},[(_vm.sizeguideDescription)?_c('div',{staticClass:"typo--h3 sizeguide-description",domProps:{"innerHTML":_vm._s(_vm.sizeguideDescription)}}):_vm._e(),_c('div',{staticClass:"typo--h3 table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"size-th"},[_vm._v(_vm._s(_vm.$labels().sizeguide_size))]),_c('th',[_vm._v(_vm._s(_vm.$labels().sizeguide_diameter))]),_c('th',[_vm._v(_vm._s(_vm.$labels().sizeguide_europe))]),_c('th',[_vm._v(_vm._s(_vm.$labels().sizeguide_uk))]),_c('th',[_vm._v(_vm._s(_vm.$labels().sizeguide_us))])])]),_c('tbody',_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:index},[_c('td',{staticClass:"size-td"},[_vm._v(_vm._s(row.flaviaSize))]),_c('td',[_vm._v(_vm._s(row.innerDiameter))]),_c('td',[_vm._v(_vm._s(row.europe))]),_c('td',[_vm._v(_vm._s(row.uk))]),_c('td',[_vm._v(_vm._s(row.us))])])}),0)])])],2)],1):_vm._e()],1):_c('div',{staticClass:"generic-option-selector-container"},_vm._l((option.values),function(value){return _c('button',{key:`${option.name.replaceAll(' ', '')}-${value.replaceAll(
                  ' ',
                  ''
                )}`,class:[
                  'option-selector',
                  {
                    selected:
                      _vm.currentSelectedOptions?.[index]?.value === value,
                  },
                  {
                    'has-color':
                      _vm.optionsWithColors?.length &&
                      _vm.optionsWithColors?.includes(option.name),
                  },
                ],attrs:{"value":value},on:{"click":function($event){return _vm.setCurrentVariant(option.name, $event)}}},[_c('div',{staticClass:"option-box",style:({
                    backgroundColor:
                      _vm.optionsWithColors?.length &&
                      _vm.optionsWithColors?.includes(option.name)
                        ? _vm.computedOptionColors.find((el) => el.name === value)
                            ?.value || 'grey'
                        : 'white',
                  })},[_c('div',{staticClass:"option-box-checked"})]),_c('div',{staticClass:"option-title typo--h3"},[_vm._v(_vm._s(value))])])}),0)])}),0):_vm._e(),_c('div',{staticClass:"product-general-info"},[(_vm.shipping?.title && _vm.shipping?.text)?_c('AccordionWrapper',[_c('AccordionItem',[_c('template',{slot:"accordion-trigger"},[_c('div',{staticClass:"trigger typo--h3"},[_c('div',{staticClass:"t-uppercase"},[_vm._v(_vm._s(_vm.shipping?.title))]),_c('Icon',{staticClass:"plus",attrs:{"name":"plus"}}),_c('Icon',{staticClass:"minus",attrs:{"name":"minus"}})],1)]),_c('template',{slot:"accordion-content"},[_c('div',{staticClass:"typo--h3 accordion-content",domProps:{"innerHTML":_vm._s(_vm.shipping?.text)}})])],2)],1):_vm._e(),(_vm.payments?.title && _vm.payments?.text)?_c('AccordionWrapper',[_c('AccordionItem',[_c('template',{slot:"accordion-trigger"},[_c('div',{staticClass:"trigger typo--h3"},[_c('div',{staticClass:"t-uppercase"},[_vm._v(_vm._s(_vm.payments?.title))]),_c('Icon',{staticClass:"plus",attrs:{"name":"plus"}}),_c('Icon',{staticClass:"minus",attrs:{"name":"minus"}})],1)]),_c('template',{slot:"accordion-content"},[_c('div',{staticClass:"typo--h3 accordion-content",domProps:{"innerHTML":_vm._s(_vm.payments?.text)}})])],2)],1):_vm._e()],1)],1),(_vm.isEnquiry)?_c('a',{staticClass:"enquire-button-desktop typo--h3--trimmed",attrs:{"href":_vm.url}},[_vm._v(" "+_vm._s(_vm.$labels().enquiry_cta)+" ")]):_c('button',{class:[
          'addtocart-button',
          'typo--h3--trimmed',
          { disabled: !_vm.currentSelectedVariant?.availableForSale },
        ],on:{"click":_vm.addToCart}},[_vm._v(" "+_vm._s(_vm.addToCartLabel)+" ")])])],1),_c('Recommendations',{attrs:{"id":_vm.product.id}}),_c('AppFooter',{staticClass:"footer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }