<template>
<div
    :class="[
      'video-player',
      data.settings?.theme === 'full' ? 'video-player--full' : false,
    ]"
    :data-index="theme === 'product' ? index : null"
  >
    <div
      v-if="settings?.source === 'url' && src"
      ref="player"
      class="plyr"
      v-html="src"
    />

    <video
      v-if="theme == 'product' && src"
      ref="player"
      class="plyr"
      playsinline
      autoplay
      muted
      loop
      :data-poster="data?.previewImage?.src ? data?.previewImage?.src : null"
      :data-index="index"
    >
      <source
        :src="src"
        type="video/mp4"
      >
    </video>

    <video
      v-if="settings?.source === 'file' && src"
      ref="player"
      class="plyr"
      playsinline
      autoplay
      muted
      loop
      :data-poster="
        data.settings.poster && data.value.poster
          ? data.value.poster.sizes.l
          : null
      "
    >
      <source
        :src="src.url"
        type="video/mp4"
      >
    </video>

    <video
      v-if="plyr && settings?.source === 'src' && src"
      ref="player"
      class="plyr"
      playsinline
      autoplay
      muted
      loop
      :data-poster="
        data.settings?.poster && data.value.poster
          ? data.value.poster.sizes.l
          : null
      "
    >
      <source
        :src="src"
        type="video/mp4"
      >
    </video>

    <Richtext
      v-if="data.value?.caption"
      :data="{ innerHTML: data.value?.caption }"
    />
    <Icon
      v-if="data.settings?.theme === 'full'"
      theme="l"
      :name="sound ? 'unmute' : 'mute'"
      :fn="toggleSound"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import Richtext from '@/components/typo/richtext';
import Icon from '@/components/ui/icon';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    ratio: {
      type: Array,
    },
    theme: {
      type: String,
      default: 'default',
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      src: null,
      currentVideo: 'default',
      plyr: null,
      sound: false,
    };
  },
  computed: {
    embed() {
      return false;
    },
    settings() {
      return this.currentVideo === 'mobile'
          && this.data.settings.mobile_toggle
          && this.data.value.src_mobile
        ? this.data.settings.mobile
        : this.data.settings;
    },
  },

  watch: {
    src(value, old) {
      if (value !== old) {
        this.$nextTick(() => {
          this.plyr = null;
          this.$nextTick(() => {
            this.mountPlyr();
          });
        });
      }
    },
    currentVideo() {
      if (this.settings.source === 'file' && this.plyr) {
        this.plyr.source = {
          type: 'video',
          sources: [
            {
              src: this.src.url,
              type: 'video/mp4',
            },
          ],
        };
      }
    },
  },
  created() {
    this.currentSrc();
  },
  mounted() {
    this.$bus.$on('windowResized', this.currentSrc);
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    mountPlyr() {
      this.plyr = new Plyr(this.$refs.player, {
        autoplay: true,
        clickToPlay: false,
        controls: [],
        fullscreen: {
          enabled: false,
        },
        loop: {
          active: true,
        },
        muted: true,
        storage: {
          enabled: false,
        },
      });
    },
    currentSrc() {
      let { src } = this.theme === 'product' ? this.data : this.data?.value;
 
      if(this.theme === 'product') {
        src = this.data.sources?.[0].url;
      }
      if (this.settings?.source === 'url') {
        src = this.data.value.url;
      }
      if (this.settings?.source === 'src') {
        src = this.data.value.src_url;
      }

      this.$nextTick(() => {
        if (this.$mq.isMobile && this.data.settings.mobile_toggle) {
          this.currentVideo = 'mobile';
          src = this.data.value.src_mobile;
          if (this.settings.source === 'url') {
            src = this.data.value.url_mobile;
          }
          if (this.settings.source === 'src') {
            src = this.data.value.src_url_mobile;
          }
        } else this.currentVideo = 'default';

        this.src = src;
      });
    },
    toggleSound() {
      if (this.$el.querySelector('video')) {
        this.$el.querySelector('video').muted = this.sound;
      } else if (this.plyr && this.plyr.muted) {
        this.plyr.muted = !this.plyr.muted;
      }
      this.sound = !this.sound;
    },
  },
};
</script>

<style lang="scss">
  .video-player {
    position: relative;
    width: 100%;

    &--full {
      background: var(--black);
      .plyr__video-embed,
      .plyr__video-wrapper--fixed-ratio {
        aspect-ratio: auto !important;
        height: calc(var(--vh) * 100);
        &__container {
        transform: translateY(calc(-120vw + 50 * var(--vh))) !important; // Considerando che Plyr fa un padding-bottom 240% cioè 2.4 width
          iframe {
            @include mq(m) {
              @media (max-aspect-ratio: 16/9) {
                left: 50%;
                transform: translateX(-50%);
                width: calc(100vh/9*16);
              }
            }
          }
        }
      }

      video {
        height: calc(var(--vh) * 100);
        width: inherit;
        object-fit: cover;
      }

      .icon {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: var(--spacer-xs);
      }
    }

    .plyr {
      --plyr-color-main: $color-white;
      --plyr-range-track-height: 2px;
      --plyr-range-thumb-height: 0px;
      --plyr-range-thumb-shadow: none;

      min-width: 0;

      .plyr__progress__buffer {
        border-radius: 0px;
      }

      .plyr__progress input[type="range"] {
        border-radius: 0px;
      }

      .plyr__tooltip {
        display: none;
      }

      .plyr__volume {
        width: auto;
        max-width: auto;
        min-width: auto;
      }
    }
  }
</style>
