<template>
  <div class="text">
    <Title
      v-if="data.value.title !== ''"
      :value="data.value.title"
      theme="uppercase"
      :typo="$mq.isMobile ? 'h3' : 'h3--trimmed'"
    />
    <RichText
      v-if="data.value.paragraphs !== ''"
      typo="p--trimmed"
      :value="data.value.paragraphs"
    />
  </div>
</template>

<script>
import Title from '@/components/typo/title';
import RichText from '@/components/typo/richtext';

export default {
  name: 'TextBlock',
  components: {
    RichText,
    Title,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  --text-columns: 1;
  display: grid;
  grid-template-columns: repeat(var(--text-columns), minmax(0, 1fr));
  gap: var(--column-gap-xs);

  @include mq(m) {
    --text-columns: 6; 
  }

  .title {
    padding-top: var(--spacer-xxs);
  }

  .richtext {
    grid-column: span 5 / -1;

    > :deep(p) {
      @include mq(m) {
        max-width: 1069px;
      }

      @include mq(l) {
        max-width: 1000px;
      }
    }
  }
}
</style>
