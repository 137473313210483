import {
  fetchCollection,
  fetchCart,
  fetchAddToCart,
  fetchCreateCart,
  fetchUpdateCartLine,
  fetchRemoveCartLine,
  fetchSetCartNote,
  fetchMenu,
  fetchMetaobjects,
} from "@/api/shopify";

export default {
  async getCollection({ commit, getters }, { params }) {
    let response = null;
    const responseFromState = getters.singleBySlug({
      type: "shopify-collection",
      slug: params.handle,
      lang: params.lang,
    });

    if (!responseFromState) {
      const {
        data: {
          data: { collection },
        },
      } = await fetchCollection({ params });
      response = collection;
    // capire se salva questa response salvare anche il paged della collection
      commit("ADD_ITEM", {
        type: "shopify-collection",
        item: response,
        lang: params.lang,
      });
    } else {
      response = responseFromState;
    }
    return response;
  },

  async getCart({ getters, commit }, { params }) {
    let response = null;
    const responseFromState = getters.cart;

    if (!responseFromState.id) {
      const { data } = await fetchCart({params});
      response = data?.data?.cart;
      commit("SET_CART", response);
    } else {
      response = responseFromState;
    }
    return response;
  },

  async getMenu({ params } ) {
    let response = null;
      const {
        data: {
          data : { menu}
        },
      } = await fetchMenu({params});

      response = menu;
    
    return response;
  },

  async getOptionColors({ getters, commit }) {
    let response = null;

    if(getters.optionColors?.length) {
      response = getters.optionColors;
      return response;
    }

    const params = {
      type: "option_color",
    };

    const {
      data: {
        data: { metaobjects : { nodes } 
        } 
      },
    } = await fetchMetaobjects({ params });

    response =  nodes;

    if (response.length) {
      commit("SET_OPTION_COLORS", response);
    }
    return response
  },

  async addToCart({ getters, commit, state }, { itemId, quantity = 1 }) {
    let response = null;
    const cartId = getters.idFromCookie;

    if (cartId) {
      const variables = {
        cartId: cartId,
        lines: [
          {
            merchandiseId: itemId,
            quantity,
          },
        ],
      };
      const {
        data: { data },
      } = await fetchAddToCart(variables);

      const errors = data.cartLinesAdd.userErrors;
      if (errors?.length) {
        console.log("errors", errors);
      }
      response = data.cartLinesAdd.cart;
    } else {
      let variables = {
        input: {
          lines: [
            {
              merchandiseId: itemId,
              quantity,
            },
          ],
        }
      };

      const {
        data: { data },
      } = await fetchCreateCart(variables);

      const errors = data?.cartCreate?.userErrors;
      if (errors?.length) {
        console.log("errors", errors);
      }

      response = data?.cartCreate?.cart;
    }

    if (response) {
      commit("SET_CART", response);
      commit("SET_CART_COOKIE", response.id);
    }
  },
  async updateCartLine({ getters, commit }, { id, quantity }) {
    let response = null;
    const cartId = getters.idFromCookie;

    if (cartId) {
      const variables = {
        cartId: cartId,
        lines: {
          id,
          quantity,
        },
      };

      const {
        data: { data },
      } = await fetchUpdateCartLine(variables);

      const errors = data.cartLinesUpdate.userErrors;
      if (errors.length) {
        console.log("errors", errors);
      }

      response = data.cartLinesUpdate.cart;
    }

    if (response) {
      commit("SET_CART", response);
    }
  },
  async removeCartLine({ getters, commit }, itemId) {
    let response = null;
    const cartId = getters.idFromCookie;
    if (cartId) {
      const variables = {
        cartId: cartId,
        lineIds: [itemId],
      };

      const {
        data: { data },
      } = await fetchRemoveCartLine(variables);
      const errors = data.cartLinesRemove.userErrors;
      if (errors.length) {
        console.log("errors", errors);
      }

      response = data.cartLinesRemove.cart;
    }

    if (response) {
      commit("SET_CART", response);
    }
  },

  async setCartNote({ getters }, note) {
    let response = null;
    const cartId = getters.idFromCookie;

    if (cartId) {
      const variables = {
        cartId: cartId,
        note: note,
      };

      const {
        data: { data },
      } = await fetchSetCartNote(variables);

      const errors = data.cartNoteUpdate.userErrors;
      if (errors.length) {
        console.log("errors", errors);
      }

      response = data.cartNoteUpdate.cart;
    }

    if (response) {
      commit("SET_CART", response);
    }
  },
};
