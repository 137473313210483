<template>
  <nav :class="['menu', theme ? `menu--${theme}` : false]">
    <div class="menu-row">
      <div v-for="item in navigation" :key="item.id" class="nav-link">
        <Link
          theme="plain"
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
        />
        <div
          class="cart-quantity typo--p--trimmed"
          v-if="(item.content === 'Cart' || item.content === 'Warenkorb') && cartQuantity > 0"
        >
          ({{ cartQuantity }})
        </div>
      </div>
    </div>
    <div class="menu-row">
      <div v-for="item in navigationRow2" :key="item.id" class="nav-link">
        <Link
          theme="plain"
          :data="{
            url: item.url,
            title: item.content,
            target: item.target,
          }"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import Link from "@/components/typo/link";

export default {
  name: "Menu",
  components: {
    Link,
  },
  props: {
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    ...mapGetters(["menu", "cart"]),
    navigation() {
      const nav = this.menu("header");
      return nav ? nav.items : null;
    },
    navigationRow2() {
      const nav = this.menu("header-2");
      return nav ? nav.items : null;
    },
    cartQuantity() {
      return this.cart?.id ? this.cart.totalQuantity : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: grid;
  gap: var(--row-gap-m);
  justify-items: start;

  .menu-row {
    display: grid;
    gap: var(--row-gap-s);
    justify-items: start;

    @include mq(m) {
      gap: var(--row-gap-xs);
    }
  }

  .router-link-exact-active {
    font-style: italic;

    & ~ .cart-quantity {
      font-style: italic;
    }
  }

  &--mobile {
    margin-top: var(--spacer-l);
    gap: var(--row-gap-l);
  }

  .nav-link {
    display: flex;
    align-items: center;
    gap: var(--column-gap-xs);
    justify-content: flex-start;
    cursor: pointer;
  }
}
</style>
