<template>
  <div :class="['gallery', theme ? `gallery--${theme}` : 'gallery--plain']">
    <div
      v-if="
        gallery && theme && data.value.gallery.length > 1 && theme !== 'product'
      "
      class="counter typo--h3--trimmed"
    >
      {{ `${gallery.selectedIndex + 1}/${data.value.gallery.length}` }}
    </div>
    <div v-if="theme === 'product'" ref="gallery" class="content">
      <div
        v-for="(item, index) in data.value ? data.value.gallery : data"
        :key="index"
        class="product-image item"
        :data-index="index"
        :data-item-id="item.id"
        :data-src="item.image.url"
      >
        <Figure
          ref="figure"
          :data="{
            value: {
              src: parsedData(item.image),
            },
            settings: {},
          }"
          theme="product"
          :lazyload="false"
          disable-ratio
          v-if="item.mediaContentType === 'IMAGE'"
        />
        <Video v-else-if="item.mediaContentType === 'VIDEO'" :data="item" />
        <button
          v-if="data.length > 1"
          class="next"
          @click="changeMaterial(true)"
        />
        <button
          v-if="data.length > 1"
          class="previous"
          @click="changeMaterial(false)"
        />
      </div>
      <!-- <div v-if="data?.options?.length" class="material-selector">
        <div
          v-for="option in data?.options"
          :class="[
            'option-container',
            { material: option.name === 'Material' || option.name === 'Color' },
          ]"
          v-show="option.name === 'Material' || option.name === 'Color'"
        >
          <div
            v-for="value in option.values"
            :style="{ backgroundColor: value }"
            :class="[
              'material-box-selector',
              {
                selected: selectedVariant?.selectedOptions.some(
                  (selectedOption) => selectedOption.value === value
                ),
              },
            ]"
          >
            <button
              @click="setCurrentVariant(option.name, $event)"
              :value="value"
            />
          </div>
        </div>
      </div> -->
    </div>
    <div
      v-else-if="theme"
      ref="gallery"
      class="content"
      :style="{ height: `${height}px` }"
    >
      <div
        v-for="item in data.value ? data.value.gallery : data"
        :key="item.id"
        class="item"
        :data-item-id="item.id"
      >
        <Figure
          ref="figure"
          :data="{
            value: {
              src: item,
            },
            settings: {},
          }"
          :theme="theme"
          :lazyload="false"
          disable-ratio
          :style="{ width: `${height * targetRatio}px` }"
        />
        <button
          v-if="data.value.gallery.length > 1"
          :style="{ width: `${height * targetRatio}px` }"
          class="next"
          @click="next"
        />
        <button
          v-if="data.value.gallery.length > 1"
          class="previous"
          :style="{ width: `${(height * item.width) / item.height / 2}px` }"
          @click="previous"
        />
      </div>
    </div>
    <div v-else ref="gallery" class="content">
      <div
        v-for="(item, index) in data.value ? data.value.gallery : data"
        :key="index"
        class="item"
        :data-item-id="item.id"
      >
        <Figure
          ref="figure"
          :data="{
            value: {
              src: item,
            },
            settings: {},
          }"
          theme="product"
          :lazyload="false"
          disable-ratio
        />
        <button
          v-if="data.length > 1"
          class="next"
          @click="changeMaterial(true)"
        />
        <button
          v-if="data.length > 1"
          class="previous"
          @click="changeMaterial(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "flickity/dist/flickity.min.css";
import Flickity from "flickity";

import Figure from "@/components/media/figure";
import Video from "@/components/media/video";

require("flickity-imagesloaded");

export default {
  name: "Gallery",
  components: {
    Figure,
    Video,
  },
  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
    variantActiveIndex: {
      type: [String, Number],
    },
    selectedVariant: {
      type: Object,
      default: () => {},
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  emits: ["change-material", "set-current-variant"],
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: "left",
        cellSelector: ".item",
        imagesLoaded: true,
        contain: "true",
        prevNextButtons: false,
        draggable: !!this.$mq.isTouchDevice,
        pageDots: this.theme == "product" ? true : false,
        setGallerySize: false,
        wrapAround: true,
      },
      targetIndex: 0,
      targetRatio: 0,
      height: 0,
      width: 0,
    };
  },
  mounted() {
    this.gallery = new Flickity(
      this.$refs.gallery,
      Object.assign(this.settings, this.extraSettings)
    );
    if (this.theme) {
      this.setTargetItem();
      this.setHeight();
      this.$bus.$on("windowResized", this.handleResize);
    }
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  beforeDestroy() {
    if (this.theme) {
      this.$bus.$off("windowResized", this.handleResize);
    }
  },
  methods: {
    setTargetItem() {
      const images = this.data.value.gallery;
      let targetRatio = 0;

      images.forEach((image, index) => {
        const { width } = image;
        const { height } = image;
        const ratio = width / height;

        if (ratio > targetRatio) {
          targetRatio = ratio;
          this.targetIndex = index;
        }
      });

      this.targetRatio = targetRatio;
    },

    parsedData(data) {
      const image = {
        src: data.url,
        sizes: {
          xl: data.url,
          l: data.url,
          m: data.url,
          s: data.url,
          "xl-width": data.width,
          "xl-height": data.height,
          "l-width": data.width,
          "l-height": data.height,
          "m-width": data.width,
          "m-height": data.height,
          "s-width": data.width,
          "s-height": data.height,
        },
        width: data.width,
        height: data.height,
      };
      return image;
    },
    setHeight() {
      const maxHeight = 600;

      const targetHeight = this.$refs.gallery.clientWidth / this.targetRatio;

      if (targetHeight < maxHeight) {
        this.height = targetHeight;
      } else this.height = maxHeight;
    },

    handleResize() {
      this.setHeight();
    },

    previous() {
      this.gallery.previous(false, true);
    },

    next() {
      this.gallery.next(false, true);
    },
    changeMaterial(direction) {
      this.$emit("change-material", direction);
    },
    // setCurrentVariant(optionName, event) {
    //   this.$emit("set-current-variant", optionName, event);
    // },
  },
  watch: {
    variantActiveIndex: {
      immediate: true,
      handler(val) {
        if (val && this.gallery) {
          this.gallery.select(val);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.gallery {
  align-items: end;
  display: grid;
  gap: var(--column-gap-xs);

  .counter {
    grid-row: 2;
  }

  @include mq(m) {
    grid-template-columns: repeat(6, minmax(0, 1fr));

    .content {
      grid-column: 2 / -1;
    }

    .counter {
      grid-row: initial;
    }
  }

  button {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
  }

  .previous {
    cursor: url("~@/assets/svg/icons/arrow-left.svg"), auto;
  }

  .next {
    cursor: url("~@/assets/svg/icons/arrow-right.svg"), auto;
  }

  &--plain {
    display: initial;

    .flickity-viewport {
      height: 100svh;

      @include mq(m) {
        height: calc(var(--vh) * 100);
      }
    }

    button {
      width: 50%;
    }

    .next {
      right: 0;
      left: initial;
    }
  }

  &--product {
    display: initial;

    .flickity-viewport {
      height: 85svh;

      @include mq(m) {
        height: calc(var(--vh) * 100);
      }
    }

    button {
      width: 50%;
    }

    .next {
      right: 0;
      left: initial;
    }
  }

  .item {
    width: 100%;
    height: 100%;

    // &:not(.is-selected) {
    //   opacity: 0;
    // }
  }

  .flickity-page-dots {
    bottom: var(--spacer-s);

    .dot {
      border-radius: 0;
      width: 4px;
      height: 4px;
      margin: 0 var(--spacer-xs);
    }
  }

  // .material-selector {
  //   position: absolute;
  //   z-index: 2;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   left: var(--spacer-xs);

  //   .option-container {
  //     display: flex;
  //     flex-direction: column;
  //     gap: var(--spacer-l);

  //     .option-selector {
  //       border: none;
  //       @extend %typo--h3;

  //       &:focus {
  //         outline: none;
  //       }

  //       &:active {
  //         outline: none;
  //       }
  //     }

  //     .material-box-selector {
  //       height: 16px;
  //       width: 16px;

  //       > button {
  //         position: relative;
  //         height: 100%;
  //         width: 100%;
  //         cursor: pointer;
  //       }

  //       &.selected {
  //         border: 1px solid black;
  //       }
  //     }
  //   }
  // }
}
</style>
