<template>
  <div class="product-preview product-preview--three-full">
    <Media v-if="value.media_two.length > 0" :data="value.media_two[0]" />
    <div class="media placeholder">
      <Media v-if="value.media_two.length > 1" :data="value.media_two[1]" />
      <Thumb :data="{ product: value.product.shopify }" placeholder />
    </div>
    <Thumb theme="collection" :data="{ product: value.product.shopify }" />
  </div>
</template>

<script>
import Media from "@/components/media";
import Thumb from "@/components/thumb/product";

export default {
  components: {
    Media,
    Thumb,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.product-preview {
  &--three-full {
    gap: var(--column-gap-xs);
    grid-template-columns: repeat(var(--preview-columns), minmax(0, 1fr));
    align-items: end;

    @include mq(m) {
      --preview-columns: 4;
    }

    > :first-child:not(.thumb) {
      grid-column: 1 / -1;
      transform: translateX(calc(-1 * var(--spacer-page)));
      width: calc(100% + 2 * var(--spacer-page));
    }

    > :nth-child(2):not(.thumb) {
      grid-row: 2;
      grid-column-end: -1;

      @include mq(m) {
        grid-column-end: initial;
        grid-column: span 2;
      }
    }

    > .thumb {
      grid-row: 2;
      grid-column-start: 1;

      @include mq(m) {
        grid-column-start: initial;
        grid-column-end: -1;
      }
    }
  }
}
</style>
