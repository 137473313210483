<template>
  <div class="archive">
    <Grid column-gap="xs" :col="{ default: 6, m: 2 }">
      <div v-if="items.length > 0" class="archive-grid">
        <div v-for="(item, index) in items" :key="`product-${index}-${paged}`" class="item">
          <component
            :is="thumbs.get('product')"
            :data="{ product: item }"
            theme="collection"
          />
        </div>
      </div>
      <div
        v-show="hasNextPage"
        ref="infinite"
        class="infinite"
      />
    </Grid>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import medusa from '@/assets/js/observer';

import Thumb from "@/components/thumb";
import ThumbProduct from "@/components/thumb/product";
import Title from "@/components/typo/title";
import Link from "@/components/typo/link";

const thumbs = new Map();
thumbs.set("default", Thumb);
thumbs.set("product", ThumbProduct);

export default {
  name: "Archive",
  components: {
    Title,
    Link,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      request: null,
      items: [],
      paged: 1,
      hasNextPage: undefined,
      cursor: undefined,
    };
  },
  computed: {
    ...mapGetters(["postType", "lang"]),
  },
  async mounted() {
    this.setRequest();
    await this.loadItems();
    if(this.hasNextPage) {
      this.setInfiniteScroll();
    }
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes("infinite-scroll")) {
      medusa.ref.removeTarget("infinite-scroll");
    }
  },
  methods: {
    async loadItems() {
      const {
        products
      } = await this.$store.dispatch("getCollection", this.request);

      if (products.nodes) {
        this.items = [...this.items, ...products.nodes];
        this.hasNextPage = products.pageInfo.hasNextPage;
        this.cursor = products.pageInfo.endCursor;
      }

      return products.nodes;
    },
    setRequest() {
      const per_page = 20;
      const filters = {};

      this.data.settings.filters.forEach((filter) => {
        const string = filter.split(":");
        // eslint-disable-next-line prefer-destructuring
        filters[string[0]] = string[1];
      });

      this.request = {
        params: {
          ...filters,
          first: per_page,
          after: this.cursor,
          sortKey: "COLLECTION_DEFAULT",
          lang: this.lang,
        },
      };
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: "infinite-scroll",
        threshold: 0.0,
        nodes: [],
        mode: "default",
        callback: this.setOffset,
        autoremove: false,
      });

      medusa.ref.pushToTarget(
        "infinite-scroll",
        this.$el.querySelector(".infinite")
      );
    },
    setOffset(entry) {
      if (entry.isIntersecting) {
        this.requestOffset();
      }
    },
    requestOffset() {
      const { height, top } = this.$el.getBoundingClientRect();
      this.paged += 1;
      this.setRequest();
      this.loadItems().then((newItems) => {
      this.$root.$children[0].lazyObserve(this.$el);

        if (newItems.length === 0) {
          medusa.ref.removeTarget("infinite-scroll");
        } else if (window.pageYOffset + window.innerHeight >= height + top) {
          this.requestOffset();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;
}

.archive-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--column-gap-xs);
  grid-column: span 2;
  row-gap: var(--spacer-xl);

  @include mq(m) {
    grid-template-columns: repeat(5, 1fr);
    grid-column: 2 / span 5;
  }
}
.infinite {
  // position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 100vh;
}
</style>
