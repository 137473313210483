export default /* GraphQL */ `
  fragment Variant on ProductVariant {
    id
    sku
    image {
      url
      altText
      width
      height
    }
    quantityAvailable
    availableForSale
    title
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    selectedOptions {
      name
      value
    }
  }
`;
