<template>
  <div class="product-preview product-preview--three-right">
    <Media v-if="value.media_two.length > 0" :data="value.media_two[0]" />
    <Media v-if="value.media_two.length > 1" :data="value.media_two[1]" />
    <Thumb theme="collection" :data="{ product: value.product.shopify }" />
  </div>
</template>

<script>
import Media from "@/components/media";
import Thumb from "@/components/thumb/product";

export default {
  components: {
    Media,
    Thumb,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.product-preview {
  &--three-right {
    column-gap: var(--column-gap-xs);
    row-gap: var(--row-gap-l);
    grid-template-columns: repeat(var(--preview-columns), minmax(0, 1fr));

    @include mq(m) {
      --preview-columns: 3;
      row-gap: var(--row-gap-xs);
    }

    > :nth-child(2):not(.thumb) {
      grid-row: 3;

      grid-column: 1 / -1;
      transform: translateX(calc(-1 * var(--spacer-page)));
      width: calc(100% + 2 * var(--spacer-page));

      @include mq(m) {
        grid-column-start: initial;
        transform: initial;
        width: 100%;
        grid-row: initial;
      }
    }

    > .thumb {
      grid-row: 2;
      grid-column-end: -1;

      @include mq(m) {
        grid-row: initial;
      }
    }
  }
}
</style>
