var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
      'video-player',
      _vm.data.settings?.theme === 'full' ? 'video-player--full' : false,
    ],attrs:{"data-index":_vm.theme === 'product' ? _vm.index : null}},[(_vm.settings?.source === 'url' && _vm.src)?_c('div',{ref:"player",staticClass:"plyr",domProps:{"innerHTML":_vm._s(_vm.src)}}):_vm._e(),(_vm.theme == 'product' && _vm.src)?_c('video',{ref:"player",staticClass:"plyr",attrs:{"playsinline":"","autoplay":"","muted":"","loop":"","data-poster":_vm.data?.previewImage?.src ? _vm.data?.previewImage?.src : null,"data-index":_vm.index},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"}})]):_vm._e(),(_vm.settings?.source === 'file' && _vm.src)?_c('video',{ref:"player",staticClass:"plyr",attrs:{"playsinline":"","autoplay":"","muted":"","loop":"","data-poster":_vm.data.settings.poster && _vm.data.value.poster
          ? _vm.data.value.poster.sizes.l
          : null},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.src.url,"type":"video/mp4"}})]):_vm._e(),(_vm.plyr && _vm.settings?.source === 'src' && _vm.src)?_c('video',{ref:"player",staticClass:"plyr",attrs:{"playsinline":"","autoplay":"","muted":"","loop":"","data-poster":_vm.data.settings?.poster && _vm.data.value.poster
          ? _vm.data.value.poster.sizes.l
          : null},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.src,"type":"video/mp4"}})]):_vm._e(),(_vm.data.value?.caption)?_c('Richtext',{attrs:{"data":{ innerHTML: _vm.data.value?.caption }}}):_vm._e(),(_vm.data.settings?.theme === 'full')?_c('Icon',{attrs:{"theme":"l","name":_vm.sound ? 'unmute' : 'mute',"fn":_vm.toggleSound}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }