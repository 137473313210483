export default {
  ADD_ITEM(state, { type, item, lang, shopify }) {
    if (item && type && !state[type][lang][item.slug]) {
      if (shopify) {
        state[type][lang][item.slug].shopify = item;
      } else {
        state[type][lang][item.slug] = item;
      }
    }
  },
  ADD_REQUEST(state, { type, request }) {
    state[type].requests.push(request);
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      Object.keys(state.postTypes).forEach((key) => {
        const restBase = state.postTypes[key].rest_base || key;
        state[restBase][lang] = {};
      });
    });
  },
};
