<template>
  <main :class="['page', $route.name === 'Home' ? 'page--home' : false]">
    <Gutenberg theme="page" :data="post.gds_blocks" />
    <AppFooter :class="{'page-padding' : $route.name === 'Home'}" />
  </main>
</template>

<script>
import data from "@/mixins/data";
import AppFooter from "@/components/ui/footer";
import Gutenberg from "@/components/blocks";

export default {
  name: "Page",
  components: {
    Gutenberg,
    AppFooter,
  },
  mixins: [data],
};
</script>

<style lang="scss">
.page {
  display: grid;
  row-gap: var(--row-gap-gutenberg);

  &:not(.page--home) {
    padding: var(--spacer-page);
    margin-top: var(--spacer-page-top);
    margin-bottom: var(--spacer-page-bottom);
  }
}
</style> 
