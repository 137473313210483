import axios from "axios";
import GDSAxios from "@/api/axios";

export const fetchSingle = ({ type, params = {} }) => {
  return new GDSAxios().ajax.get(`wp/v2/${type}/`, { params });
};

export const fetchItems = ({ type, params = {} }) => {
  // params.orderby = 'menu_order';
  if (type === "product") {
    return new GDSAxios().ajax.get("gds/v1/wc/products/", { params });
  }
  return new GDSAxios().ajax.get(`wp/v2/${type}/`, { params });
};

export const fetchSearch = ({ params, cancelToken }) =>
  new GDSAxios().ajax.get("/wp/v2/search", { params, cancelToken });

export const sendAjax = (request) => {
  const formData = new FormData();
  if (!request.action) formData.append("action", "do_ajax");

  Object.keys(request).forEach((key) => {
    if (key === "params") {
      formData.append("params", JSON.stringify(request.params));
    } else {
      formData.append(key, request[key]);
    }
  });

  // eslint-disable-next-line no-undef
  const url = __VUE_WORDPRESS__.routing.url.replace(":8888", ":3000");
  return axios.post(`${url}/wp-admin/admin-ajax.php`, formData);
};

// preview
export const fetchSingleById = ({ type, params = {} }) => {
  // 'X-WP-Nonce': nonce,

  if (type === "product") {
    return new GDSAxios().ajax.get(`gds/v1/wc/productsById/${params.id}`);
  }
  return new GDSAxios().ajax.get(`wp/v2/${type}/${params.id}`);
};
