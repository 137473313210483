<template>
  <component
    :is="tag"
    :class="[
      'title',
      `typo--${mergeProps.typo}`,
      theme !== '' ? `title--${theme}` : false,
    ]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    typo: {
      type: String,
      default: 'h3',
    },
    tag: {
      type: String,
      default: 'h2',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    mergeProps() {
      return {
        typo: this.data ? this.data.settings.typo : this.typo,
        value: this.value || this.data.value?.title,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-right: var(--spacer-l);

  &--uppercase {
    text-transform: uppercase;
  }
}
</style>
