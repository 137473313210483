import cart from "../fragments/cart";

export const add = /* GraphQL */ `
  ${cart}
  mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...Cart
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const create = /* GraphQL */ `
  ${cart}
  mutation ($input: CartInput) {
    cartCreate(input: $input) {
      cart {
        ...Cart
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const remove = /* GraphQL */ `
  ${cart}
  mutation ($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...Cart
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const update = /* GraphQL */ `
  ${cart}
  mutation ($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...Cart
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const updateNote = /* GraphQL */ `
  ${cart}
  mutation ($cartId: ID!, $note: String!) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
      cart {
        ...Cart
      }
      userErrors {
        field
        message
      }
    }
  }
`;
