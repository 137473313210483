<template>
  <div :class="['price', `typo--h3`, theme !== '' ? `price--${theme}` : false]">
    <div class="price-value">
      <span>{{ computedPrice }}</span>
      <span class="compare-at-price" v-if="computedCompareAtPrice">{{
        computedCompareAtPrice
      }}</span>
    </div>
    <span>{{ $labels().price_vat_included }}</span>
  </div>
</template>

<script>
export default {
  name: "Price",
  components: {},
  props: {
    price: {
      type: Object,
    },
    compareAtPrice: {
      type: Object,
    },
    priceRange: {
      type: Object,
    },
    compareAtPriceRange: {
      type: Object,
    },
    theme: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    computedPrice() {
      if (this?.price || this.priceRange) {
        const currency = this.priceRange
          ? this.priceRange.minVariantPrice.currencyCode
          : this?.price?.currencyCode;

        const intl = new Intl.NumberFormat("de", {
          style: "currency",
          currency,
          trailingZeroDisplay: "stripIfInteger",
        });
        const price = intl.format(
          Number(this.priceRange ? this.priceRange.minVariantPrice.amount : this?.price?.amount)
        );
        if (this.priceRange) {
          if (this.priceRange.minVariantPrice.amount != this.priceRange.maxVariantPrice.amount) {
            return `${this.$labels().price_from} ${price}`;
          } else {
            return `${price}`;
          }
        } else {
          return `${price}`;
        }
      }
    },
    computedCompareAtPrice() {
      if (this.compareAtPriceRange?.minVariantPrice?.amount > 0 || this.compareAtPrice?.amount) {
        const currency =
          this.compareAtPriceRange && Number(this.compareAtPriceRange.minVariantPrice?.amount) > 0
            ? this.compareAtPriceRange.minVariantPrice?.currencyCode
            : this.compareAtPrice?.currencyCode;
        console.log("compare", currency);

        const intl = new Intl.NumberFormat("de", {
          style: "currency",
          currency,
          trailingZeroDisplay: "stripIfInteger",
        });
        const price = intl.format(
          Number(
            this.compareAtPriceRange && Number(this.compareAtPriceRange.minVariantPrice?.amount) > 0
              ? this.compareAtPriceRange.minVariantPrice?.amount
              : this.compareAtPrice?.amount
          )
        );

        if (
          this.compareAtPriceRange &&
          Number(this.compareAtPriceRange.minVariantPrice?.amount) > 0
        ) {
          return `${price}`;
        } else if (Number(this?.compareAtPrice?.amount) > 0) {
          return `${price}`;
        }
      } else return null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.price {
  &--inline {
    > * {
      display: inline;
    }
  }

  .price-value {
    margin-right: var(--spacer-xs);
  }

  .compare-at-price {
    text-decoration: line-through;
  }
}
</style>
