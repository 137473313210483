<template>
  <div :class="['gutenberg', theme !== '' ? `gutenberg--${theme}` : false]">
    <section
      v-for="(block, index) in data"
      :key="index"
      :class="['section', `section--${parseBlockName(block.blockName)}`]"
    >
      <component
        :is="components.get(parseBlockName(block.blockName))"
        :data="block.blockName.includes('acf/') ? block.attrs.data : block"
      />
    </section>
  </div>
</template>

<script>
import { parseBlockName } from '@/assets/js/utils';
import components from '@/assets/js/components-full';

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      parseBlockName,
      components,
    };
  },
};
</script>

<style lang="scss" scoped>
.gutenberg {
  display: grid;
  // &--collection {
  //   gap: var(--row-gap-xl);
  // }
  &--page {
    gap: var(--row-gap-gutenberg);
  }
}
</style>
