/* eslint-disable no-undef */

import Cart from "@/views/shopify/Cart.vue";
import Product from "@/views/shopify/Product.vue";

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const permalink = postTypes["wps-product"].rewrite.slug.replace(/^\/+/g, "");
const type = postTypes["wps-product"].rest_base;

let langs = null;

if (i18n) {
  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join("|");
}

const shopifyRoutes = [
  {
    path: langs ? `/:lang(${langs})?/cart` : "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      customView: true,
    },
  },
  {
    path: langs ? `/:lang(${langs})?/${permalink}/:slug` : `/${permalink}/:slug`,
    name: "Product",
    component: Product,
    meta: {
      type: "wps-product",
      shopify: true,
    },
  },
];

export default shopifyRoutes;
