<template>
  <div id="app">
    <AppHeader />
    <Jewel />
    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <router-view :key="$route.path" />
    </transition>

    <Modal />
    <Snackbar />
    <Widgets :key="lang" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';

import Modal from '@/components/ui/modal';
import Jewel from '@/components/ui/jewel';
import Snackbar from '@/components/ui/snackbar';
import Widgets from '@/components/ui/widgets';

export default {
  name: 'App',
  components: {
    AppHeader,
    Jewel,
    Modal,
    Widgets,
    Snackbar,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost', 'lang']),
  },
  async created() {
    medusa.init();
    let id = Cookies.get("flaviatschanz-cart");
    if(id) {
      await this.$store.dispatch("getCart", {params: {cartId: id, lang: this.lang} } );
    }
    await this.$store.dispatch('getOptionColors');
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }),
    );
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/style.scss";

  .app-loaded {
    #loader {
      display: none;
    }
  }
</style>
