<template>
  <transition name="fade">
    <Flex
      v-show="show"
      :class="['modal', `modal-theme--${theme}`, 'overlay']"
      align="center"
      :tabindex="show ? 0 : -1"
    >
      <Wrapper v-if="data" :class="['modal-wrapper']" size="m" boxed>
        <Icon
          v-if="!data.mandatory"
          name="close"
          :fn="close"
          theme="minimal"
          size="m"
          class="icon--close"
        />

        <template v-if="data.type === 'content'">
          <Spacer all="m">
            <Title :value="data.content.title" />
            <Richtext :value="data.content.richtext" />
            <Flex justify="center">
              <Link :data="data.content.cta.title" theme="button" />
            </Flex>
          </Spacer>
        </template>

        <template v-if="data.type === 'dialog'">
          <Spacer all="m">
            <Grid col="12">
              <Title :value="data.content.title" />
              <Richtext :value="data.content.richtext" />
              <Flex justify="center">
                <Link
                  :data="{
                    fn: dialogFn,
                    title: data.content.cta.title,
                  }"
                  theme="button"
                />
              </Flex>
            </Grid>
          </Spacer>
        </template>

        <template v-if="data.type === 'cart'">
          <Grid col="12" class="modal-cart">
            <Title :value="data.content.title" />
            <Richtext :value="data.content.richtext" />
            <Flex justify="start" column-gap="xs" row-gap="xs">
              <Link
                :data="{
                  url: data.content.cta.url,
                  title: data.content.cta.title,
                }"
              />
              <span class="typo--p">{{ $labels().addtocart_modal_or }}</span>
              <Link
                :data="{
                  url: data.content.cta_checkout.url,
                  title: data.content.cta_checkout.title,
                }"
              />
            </Flex>
          </Grid>
        </template>
      </Wrapper>
    </Flex>
  </transition>
</template>

<script>
import Icon from "@/components/ui/icon";
import Title from "@/components/typo/title";
import Richtext from "@/components/typo/richtext";
import Link from "@/components/typo/link";

export default {
  name: "Modal",
  components: {
    Icon,
    Title,
    Richtext,
    Link,
  },
  data() {
    return {
      show: false,
      data: null,
      force: true,
    };
  },
  computed: {
    theme() {
      let theme = "default";
      if (this.data && this.data.theme) theme = this.data.theme;

      return theme;
    },
  },
  watch: {
    show(val) {
      if (val) {
        document.body.classList.add("overflow-hidden");
        document.body.addEventListener("keydown", this.close);
      } else {
        document.body.classList.remove("overflow-hidden");
        document.body.removeEventListener("keydown", this.close);
      }
    },
    $route(val, oldVal) {
      if (val !== oldVal) {
        this.show = false;
      }
    },
  },
  mounted() {
    this.$bus.$on("modal", this.init);
    this.$bus.$on("openModal", this.open);
    this.$bus.$on("populateModal", this.content);
    this.$bus.$on("removeContentModal", this.remove);
  },
  beforeDestroy() {
    this.$bus.$off("modal", this.init);
    this.$bus.$off("openModal", this.open);
    this.$bus.$off("populateModal", this.content);
    this.$bus.$off("removeContentModal", this.remove);
  },
  methods: {
    init(data) {
      this.data = data;
      this.show = true;
      // if(data.theme == 'cart') {
      //   setTimeout(() => {
      //     this.show = false;
      //     this.data = null;
      //   }, 3000);
      // }
    },
    content(data) {
      this.data = data;
      this.force = false;
    },
    open(id) {
      if (id === this.data.id) {
        this.show = true;
      }
    },
    close(e) {
      if (e.keyCode === 27 || e.type === "click") {
        this.show = false;

        if (this.force) this.data = null;
      }
    },
    remove(id) {
      if (id) {
        if (this.data.id === id) {
          this.show = false;
          this.data = null;
        }
      }
    },
    dialogFn() {
      this.data.fn();
      this.remove(this.data.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;

  &-theme--default {
    .modal-wrapper {
      max-height: 80vh;
      background: var(--white);
      border-radius: var(--border-radius);
    }
  }

  &-theme--cart {
    left: unset;
    top: unset;
    right: var(--spacer-xs);
    bottom: calc(var(--spacer-xs) * 2 + 26px);
    height: fit-content;
    width: calc(100% - 2 * var(--spacer-xs));
    border: 1px solid var(--grey);
    background: var(--semi-transparent);
    border-radius: var(--border-radius);
    padding-top: var(--spacer-s);
    padding-bottom: var(--spacer-s);

    @include mq(m) {
      width: calc(50% / 12 * 4 - var(--spacer-xs));
    }

    .modal-wrapper {
      display: grid;
      grid-auto-flow: column;
      padding-left: var(--spacer-s);
      padding-right: var(--spacer-s);

      .icon--close {
        order: 2;
        width: fit-content;
        justify-self: flex-end;
        padding: 3px;
      }
    }

    .modal-cart {
      .title {
        margin-bottom: var(--spacer-xs);
      }
      .richtext {
        margin-bottom: var(--spacer-l2);
      }

      .link {
        color: var(--orange);
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}
</style>
