<template>
  <main class="collection">
    <Gutenberg
      :data="post.gds_blocks"
      theme="collection"
    />
    <AppFooter />
  </main>
</template>

<script>
import Gutenberg from '@/components/blocks';
import data from '@/mixins/data';
import AppFooter from '@/components/ui/footer';


export default {
  name: 'Collection',
  components: {
    Gutenberg,
    AppFooter,
  },
  mixins: [data],
};
</script>

<style lang="scss" scoped>
  .collection {
    display: grid;
    row-gap: var(--row-gap-gutenberg);
    padding: var(--spacer-page);
    margin-top: var(--spacer-page-top);
  }
</style>
