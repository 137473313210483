<template>
  <div
    :class="[
      'richtext',
      `typo--${typo}`,
      theme !== '' ? `richtext--${theme}` : false,
    ]"
    v-html="computedValue"
  />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    typo: {
      type: String,
      default: 'p',
    },
    value: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedValue() {
      let value = '';
      if (this.data && this.data.innerHTML !== '') {
        value = this.data.innerHTML;
      }

      if (this.value && this.value !== '') {
        value = this.value;
      }

      return value;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (window.twttr && this.mergeProps.value.includes('twitter-tweet')) {
      // eslint-disable-next-line no-undef
      window.twttr.widgets.load();
    }

    // // eslint-disable-next-line no-undef
    // FB.init({
    //   xfbml: true,
    //   version: 'v10.0',
    // });
  },
};
</script>

<style lang="scss">
  .richtext {
    a {
      text-decoration: none;
      display: inline;

      @extend %typo--link;

      @media (hover: hover) {
        color: var(--grey);

        &:hover {
          color: var(--black);
        }
      }
      &.black {
        color: var(--black);
      }
    }

    h5 {
      @extend %typo--h3;
      margin-bottom: var(--spacer-xs);
    }

    &--italic {
      font-style: italic;
      // white-space: pre-line;
    }

    &--social.richtext {
      a {
        color: var(--black);

        @extend %typo--p;
      }
    }
  }
</style>
