<template>
  <div class="product-preview product-preview--three-inline">
    <div class="media placeholder">
      <Media v-if="value.media_two.length > 0" :data="value.media_two[0]" />
      <Thumb :data="{ product: value.product.shopify }" placeholder />
    </div>
    <div class="media placeholder">
      <Media v-if="value.media_two.length > 1" :data="value.media_two[1]" />
      <Thumb :data="{ product: value.product.shopify }" placeholder />
    </div>
    <Thumb theme="collection" :data="{ product: value.product.shopify }" />
  </div>
</template>

<script>
import Media from "@/components/media";
import Thumb from "@/components/thumb/product";

export default {
  components: {
    Media,
    Thumb,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.product-preview {
  &--three-inline {
    column-gap: var(--column-gap-xs);
    row-gap: var(--row-gap-l);
    grid-template-columns: repeat(var(--preview-columns), minmax(0, 1fr));
    align-items: end;

    > :first-child:not(.thumb) {
      grid-column: 1 / -1;
      transform: translateX(calc(-1 * var(--spacer-page)));
      width: calc(100% + 2 * var(--spacer-page));

      @include mq(m) {
        grid-column: initial;
        transform: initial;
        width: 100%;
      }
    }

    @include mq(m) {
      --preview-columns: 4;
    }

    > .thumb {
      grid-column-end: -1;
    }

    .placeholder:first-child {
      .thumb {
        display: none;

        @include mq(m) {
          display: grid;
        }
      }
    }
  }
}
</style>
