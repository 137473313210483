<template>
  <main class="product">
    <div class="product-content">
      <div
        v-if="productGallery?.length > 1 && !$mq.isMobile"
        class="product-gallery-desktop-pagination"
      >
        <div class="pagination-container">
          <div
            v-for="(media, index) in productGallery"
            :class="['pagination-button', { active: index == activeImage }]"
            :data-index="index"
            @click="setActiveMediaIndex(index)"
          />
        </div>
      </div>
      <Gallery
        v-if="productGallery?.length && $mq.isMobile"
        :data="{ value: { gallery: productGallery }, options: productOptions }"
        :variantActiveIndex="activeIndex"
        :currentSelectedVariant="currentSelectedVariant"
        :theme="'product'"
      />
      <div v-else-if="productGallery?.length" class="product-gallery-desktop">
        <div v-for="(media, index) in productGallery" :key="`image${index}`">
          <Figure
            :data="{
              value: {
                src: parsedData(media.image),
              },
              settings: {},
            }"
            :class="['product-image', { active: index == activeImage }]"
            theme="product"
            :ref="`image${index}`"
            :data-index="index"
            :data-src="media.image?.src"
            :lazyload="false"
            disable-ratio
            v-if="media.mediaContentType === 'IMAGE'"
          />
          <Video v-else-if="media.mediaContentType === 'VIDEO'" :data="media" :src="media?.sources?.[0]?.url" theme="product" :ref="`image${index}`" :index="index"/>
        </div>
      </div>
      <div class="product-information">
        <div class="first-box">
          <Title
            :value="product?.title"
            typo="h3"
            class="product-title"
            v-if="product?.title"
          />
          <Price
            theme="inline"
            class="product-price"
            :price="currentSelectedVariant?.price"
            :compareAtPrice="currentSelectedVariant?.compareAtPrice"
          />
          <a
            v-if="isEnquiry"
            :href="url"
            class="enquire-button-mobile typo--h3--trimmed"
          >
            {{ $labels().enquiry_cta }}
          </a>
          <button
            v-else
            @click="addToCart"
            :class="[
              'addtocart-button-mobile',
              'typo--h3--trimmed',
              { disabled: !currentSelectedVariant?.availableForSale },
            ]"
          >
            {{ addToCartLabel }}
          </button>
          <Richtext
            theme="italic"
            typo="h3"
            :value="product.descriptionHtml"
            class="product-description"
            v-if="product?.descriptionHtml"
          />
          <!-- <div v-if="currentSelectedVariant?.title" class="typo--h3--trimmed product-selected-variant">{{ currentSelectedVariant?.title }}</div> -->
          <div
            v-if="
              product?.options?.length &&
              product.variants.nodes[0].title !== 'Default Title'
            "
            class="product-selector"
          >
            <div
              v-for="(option, index) in product.options"
              :key="option.name"
              :class="[
                'option-container',
                {
                  'size-selector-container':
                    option.name === 'Size' ||
                    option.name === 'size' ||
                    option.name === 'Size' ||
                    option.name === 'Größe' ||
                    option.name === 'größe',
                },
              ]"
            >
              <div class="typo--h3">{{ option.name }}</div>
              <div
                v-if="
                  option.name === 'Size' ||
                  option.name === 'size' ||
                  option.name === 'Größe' ||
                  option.name === 'größe' || 
                  option.name === 'Ringgröße' ||
                  option.name === 'Ringgrösse'
                "
              >
                <select
                  class="size-selector"
                  @change="setCurrentVariant(option.name, $event)"
                >
                  <option
                    v-for="value in option.values"
                    :key="`${option.name.replaceAll(
                      ' ',
                      ''
                    )}-${value.replaceAll(' ', '')}`"
                  >
                    {{ value }}
                  </option>
                </select>
                <AccordionWrapper
                  class="product-sizeguide"
                  v-if="tableData.length"
                >
                  <AccordionItem>
                    <template slot="accordion-trigger">
                      <div class="trigger typo--h3">{{$labels().sizeguide_title}}</div>
                    </template>
                    <template slot="accordion-content">
                      <div
                        class="typo--h3 sizeguide-description"
                        v-if="sizeguideDescription"
                        v-html="sizeguideDescription"
                      ></div>
                      <div class="typo--h3 table">
                        <thead>
                          <tr>
                            <th class="size-th">{{$labels().sizeguide_size}}</th>
                            <th>{{$labels().sizeguide_diameter}}</th>
                            <th>{{$labels().sizeguide_europe}}</th>
                            <th>{{$labels().sizeguide_uk}}</th>
                            <th>{{$labels().sizeguide_us}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in tableData" :key="index">
                            <td class="size-td">{{ row.flaviaSize }}</td>
                            <td>{{ row.innerDiameter }}</td>
                            <td>{{ row.europe }}</td>
                            <td>{{ row.uk }}</td>
                            <td>{{ row.us }}</td>
                          </tr>
                        </tbody>
                      </div>
                    </template>
                  </AccordionItem>
                </AccordionWrapper>
              </div>
              <div v-else class="generic-option-selector-container">
                <button
                  v-for="value in option.values"
                  :key="`${option.name.replaceAll(' ', '')}-${value.replaceAll(
                    ' ',
                    ''
                  )}`"
                  :value="value"
                  :class="[
                    'option-selector',
                    {
                      selected:
                        currentSelectedOptions?.[index]?.value === value,
                    },
                    {
                      'has-color':
                        optionsWithColors?.length &&
                        optionsWithColors?.includes(option.name),
                    },
                  ]"
                  @click="setCurrentVariant(option.name, $event)"
                >
                  <div
                    class="option-box"
                    :style="{
                      backgroundColor:
                        optionsWithColors?.length &&
                        optionsWithColors?.includes(option.name)
                          ? computedOptionColors.find((el) => el.name === value)
                              ?.value || 'grey'
                          : 'white',
                    }"
                  >
                    <div class="option-box-checked"></div>
                  </div>
                  <div class="option-title typo--h3">{{ value }}</div>
                </button>
              </div>
            </div>
          </div>
          <div class="product-general-info">
            <AccordionWrapper
              v-if="shipping?.title && shipping?.text"
            >
              <AccordionItem>
                <template slot="accordion-trigger">
                  <div class="trigger typo--h3">
                    <div class="t-uppercase">{{ shipping?.title }}</div>
                    <Icon name="plus" class="plus" />
                    <Icon name="minus" class="minus" />
                  </div>
                </template>
                <template slot="accordion-content">
                  <div
                    class="typo--h3 accordion-content"
                    v-html="shipping?.text"
                  ></div>
                </template>
              </AccordionItem>
            </AccordionWrapper>
            <AccordionWrapper
              v-if="payments?.title && payments?.text"
            >
              <AccordionItem>
                <template slot="accordion-trigger">
                  <div class="trigger typo--h3">
                    <div class="t-uppercase">{{ payments?.title }}</div>
                    <Icon name="plus" class="plus" />
                    <Icon name="minus" class="minus" />
                  </div>
                </template>
                <template slot="accordion-content">
                  <div
                    class="typo--h3 accordion-content"
                    v-html="payments?.text"
                  ></div>
                </template>
              </AccordionItem>
            </AccordionWrapper>
          </div>
        </div>
        <a
          v-if="isEnquiry"
          :href="url"
          class="enquire-button-desktop typo--h3--trimmed"
        >
          {{ $labels().enquiry_cta }}
        </a>
        <button
          v-else
          @click="addToCart"
          :class="[
            'addtocart-button',
            'typo--h3--trimmed',
            { disabled: !currentSelectedVariant?.availableForSale },
          ]"
        >
          {{ addToCartLabel }}
        </button>
      </div>
    </div>
    <Recommendations :id="product.id" />
    <AppFooter class="footer" />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import data from "@/mixins/data";
import Gallery from "@/components/media/gallery";
import Figure from "@/components/media/figure";
import Video from "@/components/media/video";
import Title from "@/components/typo/title";
import Enquiry from "@/components/ui/enquiry";
import Richtext from "@/components/typo/richtext";
import Price from "@/components/shopify/price";
import AccordionWrapper from "@/components/ui/accordion-wrapper";
import AccordionItem from "@/components/ui/accordion-item";
import Icon from "@/components/ui/icon";
import Recommendations from "@/components/shopify/recommendations";
import AppFooter from "@/components/ui/footer";

export default {
  name: "Product",
  components: {
    Gallery,
    Figure,
    Video,
    Title,
    Richtext,
    Enquiry,
    Price,
    AccordionWrapper,
    AccordionItem,
    Icon,
    Recommendations,
    AppFooter,
  },
  mixins: [data],
  data() {
    return {
      product: null,
      currentSelectedVariant: null,
      currentSelectedVariantImage: null,
      currentSelectedOptions: null,
      activeIndex: 0,
      activeImage: 0,
      tableData: [],
      sizeguideDescription: null,
    };
  },
  computed: {
    ...mapGetters(["cart", "options", "optionColors", "lang"]),
    shipping() {
      const { shipping, 'shipping-de': shippingDe } = this.options;
      return this.lang === 'de' ? shippingDe : shipping;
    },
    payments() {
      const { payments, 'payments-de': paymentsDe } = this.options;
      return this.lang === 'de' ? paymentsDe : payments;
    },
    productGallery() {
      const media = this.product.media.nodes;
      return media;
    },
    isEnquiry() {
      return this.product?.tags.some((tag) => tag === "enquire");
    },
    url() {
      const email = "info@flaviatschanz.ch";
      const subject = `${this.$labels().enquiry_subject} ${this.product.title}`;
      let body = this.$labels().enquiry_block_body;
      return `mailto:${email}?subject=${subject}&body=${body}`;
    },
    addToCartLabel() {
      return this.currentSelectedVariant?.availableForSale
        ? this.$labels().add_to_cart
        : this.$labels().sold_out;
    },
    productOptions() {
      return this.product.options;
    },
    optionsWithColors() {
      return this.product.options_with_color?.value;
    },
    computedOptionColors() {
      if (!this.optionColors) return [];
      return this.optionColors.map((color) => {
        let colorName = color.fields.find(
          (field) => field.key === "option_value_name"
        )?.value;
        let colorValue = color.fields.find(
          (field) => field.key === "color"
        )?.value;

        return {
          name: colorName,
          value: colorValue,
        };
      });
    },
  },
  methods: {
    // parsare anche video
    parsedData(data) {
      const image = {
        src: data.url,
        sizes: {
          xl: data.url,
          l: data.url,
          m: data.url,
          s: data.url,
          "xl-width": data.width,
          "xl-height": data.height,
          "l-width": data.width,
          "l-height": data.height,
          "m-width": data.width,
          "m-height": data.height,
          "s-width": data.width,
          "s-height": data.height,
        },
        width: data.width,
        height: data.height,
      };
      return image;
    },
    setActiveMediaIndex(index) {
      this.activeIndex = index;
      const el = document.querySelector(
        `.product-image[data-index="${index}"]`
      ) || document.querySelector(`.video-player[data-index="${index}"]`);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      else {}
    },
    setCurrentVariant(optionName, event) {
      let newOptionValue = event.target.value;
      this.currentSelectedOptions = this.currentSelectedOptions.map(
        (option) => {
          if (option.name === optionName) {
            return { ...option, value: newOptionValue };
          }
          return option;
        }
      );

      this.currentSelectedVariant = this.product.variants.nodes.find(
        (variant) => {
          return (
            JSON.stringify(variant.selectedOptions) ===
            JSON.stringify(this.currentSelectedOptions)
          );
        }
      );

      if (this.currentSelectedVariant?.image) {
        this.currentSelectedVariantImage =
          this.currentSelectedVariant.image.url;
        const el = document.querySelector(
          `.product-image[data-src="${this.currentSelectedVariantImage}"]`
        );
        if (el) {
          const index = el.getAttribute("data-index");
          this.setActiveMediaIndex(index);
        }
      } else {
        this.currentSelectedVariantImage =
          this.product.variants.nodes?.[0].image.url;
      }
    },
    openModal() {
      this.$bus.$emit("modal", {
        type: "cart",
        theme: "cart",
        id: "addToCart",
        content: {
          title: this.$labels().addtocart_modal_title,
          richtext: this.$labels().addtocart_modal_text,
          cta: {
            title: this.$labels().addtocart_modal_cta,
            url: "/cart",
          },
          cta_checkout: {
            title: this.$labels().addtocart_modal_cta_checkout,
            url: this.cart.checkoutUrl,
          },
        },
      });
    },
    async addToCart() {
      if (this.currentSelectedVariant.availableForSale) {
        await this.$store.dispatch("addToCart", {
          itemId: this.currentSelectedVariant.id,
        });
        this.openModal();
      } else {
        console.log("VARIANT NOT AVAILABLE", this.currentSelectedVariant);
      }
    },
    initIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.9,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const index = entry.target.getAttribute("data-index");
          if (entry.isIntersecting) {
            this.activeImage = index;
          }
        });
      }, options);

      // Observe each image
      if (!this.$mq.isMobile) {
        this.productGallery.forEach((image, index) => {
          const imgElement = this.$refs[`image${index}`][0].$el;
          observer.observe(imgElement);
        });
      }
    },
  },
  async created() {
    this.product = this.post.shopify;
  },
  async mounted() {
    this.currentSelectedVariant = this.product.variants.nodes[0];
    this.currentSelectedOptions = this.currentSelectedVariant.selectedOptions;
    this.currentSelectedVariantImage =
      this.currentSelectedVariant?.image?.url ||
      this.product.variants.nodes[0].image.url;

    if (!this.$mq.isMobile) {
      this.initIntersectionObserver();
    }

    if (this.product.collections?.nodes?.length) {
      let collection = this.product.collections?.nodes.find(
        (node) => node.sizeguide?.value
      );

      if (collection) {
        let sizeguide = collection.sizeguide?.value;
        let description = collection.sizeguide_description?.value;
        if (sizeguide) {
          this.tableData = JSON.parse(sizeguide).tableData;
        }
        if (description) {
          this.sizeguideDescription = description;
        }
      }
    }

    if (this.product.sizeguide?.value) {
      this.tableData = JSON.parse(this.product.sizeguide.value).tableData;
    }
    if (this.product.sizeguide_description?.value) {
      this.sizeguideDescription = this.product.sizeguide_description.value;
    }
  },
};
</script>

<style lang="scss" scoped>
.product {
  .product-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--spacer-xs);

    @include mq(m) {
      grid-template-columns: repeat(12, 1fr);
      padding: 0 var(--spacer-xs);
    }

    .product-gallery-desktop-pagination {
      @include mq(m) {
        grid-column: span 2;
        height: 100vh;
        position: sticky;
        top: 0;
        left: 0;
        align-items: center;
        display: flex;

        .pagination-container {
          display: flex;
          flex-direction: column;
          gap: var(--spacer-xs);
        }

        .pagination-button {
          height: 4px;
          width: 4px;
          background-color: var(--grey);
          cursor: pointer;

          &.active {
            background-color: var(--black);
          }
        }
      }
    }

    .product-gallery-desktop {
      @include mq(m) {
        grid-column: 3 / span 6;
      }
    }

    .product-information {
      padding: 0 var(--spacer-xs);
      @include mq(m) {
        grid-column: span 4;
        height: calc(100vh - var(--spacer-xs));
        position: sticky;
        top: 0;
        display: grid;
        grid-template-rows: 1fr auto;
        padding: var(--spacer-xs) 0 0 0;
        overflow-y: scroll;
      }
    }

    .first-box {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .product-title {
      margin-bottom: var(--spacer-l);
      order: 0;

      @include mq(m) {
        margin-bottom: var(--spacer-m);
      }
    }

    .product-price {
      order: 3;
      margin-bottom: var(--spacer-xs);

      @include mq(m) {
        margin-bottom: var(--spacer-l);
        order: 1;
      }
    }

    .product-description {
      font-style: italic;
      margin-bottom: var(--spacer-l2);
      order: 1;

      @include mq(m) {
        margin-bottom: var(--spacer-xl);
        order: 2;
        max-width: 314px;
      }
    }

    .product-selector {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--spacer-xs);
      row-gap: var(--spacer-l2);
      order: 2;
      margin-bottom: var(--spacer-l2);

      @include mq(m) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: var(--spacer-xs);
        row-gap: var(--spacer-l);
        order: 3;
        margin-bottom: var(--spacer-xl);
      }
    }

    .option-container {
      display: grid;
      grid-template-rows: auto 1fr;

      gap: var(--spacer-m);

      &.size-selector-container {
        gap: var(--spacer-xs);
      }

      .size-selector {
        cursor: pointer;
        border: none;
        @extend %typo--h3;

        margin-bottom: var(--spacer-xs);
        @include mq(m) {
          margin-bottom: 0;
        }

        &:focus {
          outline: none;
        }

        &:active {
          outline: none;
        }
      }
    }

    .size-selector-container {
      order: 4;
      grid-column: 1 / span 2;

      @include mq(m) {
        grid-column: 1 / span 3;
      }
    }

    .product-sizeguide {
      order: 2;

      .trigger {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 3px;
        cursor: pointer;
      }

      @include mq(m) {
        order: 3;
        margin-top: var(--spacer-xs);
        .sizeguide-description {
          max-width: 85%;
          margin-bottom: var(--spacer-m);
          margin-top: var(--spacer-xs);
        }
      }

      .table {
        border-collapse: collapse;

        @include mq(m) {
          max-width: 500px;
        }

        tr {
          border-bottom: 1px solid var(--black);
        }
        td,
        th {
          padding: 10px;
          white-space: nowrap;
        }

        .size-th, .side-td {
          width: 100%;
        }

      }
    }

    .generic-option-selector-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-xs);

      .option-selector {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacer-xs);
        white-space: nowrap;

        .option-box {
          height: 14px;
          width: 14px;
          border: 1px solid var(--grey);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .option-box-checked {
          height: 6px;
          width: 6px;
          background-color: transparent;
        }

        &.has-color {
          .option-box {
            border: none;
          }

          &.selected {
            .option-box {
              border: 1px solid var(--black);
            }
          }

          .option-box-checked {
            display: none;
          }
        }

        &.selected {
          .option-box-checked {
            background-color: var(--black);
          }
        }
      }

      .option-title,
      .option-box {
        pointer-events: none;
      }
    }

    .product-general-info {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-xs);
      order: 3;
      margin-bottom: var(--spacer-xl);

      @include mq(m) {
        gap: 2px;
      }

      .trigger {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: var(--spacer-xs);
        cursor: pointer;

        .t-uppercase {
          text-transform: uppercase;
        }

        :deep(.icon) {
          pointer-events: none;
        }
      }
    }

    .addtocart-button,
    .enquire-button-desktop {
      display: none;
      text-align: left;
      background-color: var(--orange);
      padding: var(--spacer-s);
      width: 100%;
      text-decoration: none;
      &.disabled {
        cursor: not-allowed;
        background-color: var(--grey);
      }
      @include mq(m) {
        display: block;
        position: sticky;
        bottom: 0;

        @media (hover: hover) {
          &:hover {
            background-color: var(--black);
            color: var(--white);
          }
        }
      }
    }

    .addtocart-button-mobile,
    .enquire-button-mobile {
      order: 3;
      text-align: left;
      background-color: var(--orange);
      padding: var(--spacer-s);
      width: 100%;
      margin-bottom: var(--spacer-xl);
      text-decoration: none;

      &.disabled {
        cursor: not-allowed;
        background-color: var(--grey);
      }
      @include mq(m) {
        display: none;
      }
    }
  }

  .accordion__trigger {
    .minus {
      display: none;
    }

    .plus {
      display: grid;
    }
    &--active {
      .minus {
        display: grid;
      }

      .plus {
        display: none;
      }
    }
  }

  .footer {
    margin-top: var(--row-gap-gutenberg);
    padding: var(--spacer-page);
    padding-top: 0;
  }
}
</style>
