<template>
  <div>
    <ValidationProvider
      v-if="!data.hidden && !isEmpty(data.options)"
      key="select"
      v-slot="{ errors, classes }"
      mode="lazy"
      :rules="data.rules"
      :vid="data.vid"
      tag="div"
    >
      <div
        :class="[
          'field field--select',
          ...classes,
          { empty: labelEmpty },
          { focus: labelFocus },
        ]"
      >
        <slot
          name="label"
          class="field-label"
        />
        <div class="field-input">
          <select
            :id="formId ? `${formId}-${data.id || $vnode.key}` : data.id || $vnode.key"
            v-model.trim="computedModel"
            :name="data.id || $vnode.key"
            @change="data.fn ? data.fn() : false"
            @blur="labelPosition"
            @focus="labelPosition"
          >
            <option
              v-for="(option, optionKey) in data.options"
              :key="optionKey"
              :value="optionKey"
              v-html="option"
            />
          </select>
          <Icon
            name="alert"
            theme="warning"
            size="s"
          />
          <Icon
            v-if="data.tooltip"
            name="help"
            size="s"
          />
        </div>
      </div>
      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>
    </ValidationProvider>
    <ValidationProvider
      v-else
      key="input"
      v-slot="{ errors, classes }"
      mode="lazy"
      :rules="data.rules"
      :vid="data.vid"
    >
      <div
        :class="[
          'field field--input',
          ...classes,
          { empty: labelEmpty },
          { focus: labelFocus },
        ]"
      >
        <slot
          name="label"
          class="field-label"
        />
        <div class="field-input">
          <input
            :id="`${formId || ''}-${data.id || $vnode.key}`"
            v-model.trim="computedModel"
            type="text"
            :name="data.id || $vnode.key"
            @change="data.fn ? data.fn() : false"
            @blur="labelPosition"
            @focus="labelPosition"
          >
          <Icon
            name="alert"
            theme="warning"
            size="s"
          />
          <Icon
            v-if="data.tooltip"
            name="help"
            size="s"
          />
        </div>
      </div>
      <div
        slot="errors"
        class="input-errors"
      >
        {{ errors[0] }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import isEmpty from 'lodash.isempty';
import { ValidationProvider } from 'vee-validate';
import Icon from '@/components/ui/icon';

export default {
  name: 'Select',
  components: {
    ValidationProvider,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      default: '',
    },
    formId: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      labelFocus: false,
    };
  },
  computed: {
    labelEmpty() {
      return isEmpty(this.computedModel);
    },
    computedModel: {
      get() {
        return this.model;
      },
      set(val) {
        if (isEmpty(val)) {
          this.$emit('update:model', null);
        } else {
          this.$emit('update:model', val);
        }
      },
    },
  },
  methods: {
    isEmpty(data) {
      return isEmpty(data);
    },
    labelPosition(e) {
      if (e.type === 'focus') {
        this.labelFocus = true;
      }
      if (e.type === 'blur') {
        this.labelFocus = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
