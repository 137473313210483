<template>
  <main class="cart">
    <div class="cart-content">
      <div class="title typo--p">{{ $labels().cart_title }}</div>
      <div class="cart-lines" v-if="cart?.lines?.nodes.length">
        <div v-for="line in lines" :key="line.id">
          <ThumbProduct
            :data="{ variant: line.merchandise }"
            :quantity="line.quantity"
            theme="cart"
            @remove="removeItem(line.id)"
            @update="(quantity) => updateItem(line.id, quantity)"
          />
        </div>
      </div>
      <div v-else class="cart-empty">
        <div class="typo--h3">{{ $labels().cart_empty }}</div>
      </div>
      <div class="space-block"></div>
      <div class="cart-totals" v-if="cart?.lines?.nodes.length">
        <div class="cart-subtotal">
          <div class="typo--h3">{{ $labels().cart_subtotal }}</div>
          <Price
            theme="inline"
            :price="cart?.cost?.subtotalAmount"
            class="t-right"
          ></Price>
        </div>
        <div class="cart-shipping">
          <div class="typo--h3">{{ $labels().cart_shipping }}</div>
          <div class="typo--h3 t-right">{{ $labels().cart_shipping_text }}</div>
        </div>
        <div class="cart-information">
          <AccordionWrapper
            v-if="payments?.title && payments?.text"
          >
            <AccordionItem>
              <template slot="accordion-trigger">
                <div class="trigger">
                  <div class="accordion-title typo--h3">
                    {{ payments?.title }}
                  </div>
                  <Icon name="plus" class="plus" />
                  <Icon name="minus" class="minus" />
                </div>
              </template>
              <template slot="accordion-content">
                <div
                  class="typo--h3 accordion-content"
                  v-html="payments?.text"
                ></div>
              </template>
            </AccordionItem>
          </AccordionWrapper>
          <AccordionWrapper
            v-if="shipping?.title && shipping?.text"
          >
            <AccordionItem>
              <template slot="accordion-trigger">
                <div class="trigger">
                  <div class="accordion-title typo--h3">
                    {{ shipping?.title }}
                  </div>
                  <Icon name="plus" class="plus" />
                  <Icon name="minus" class="minus" />
                </div>
              </template>
              <template slot="accordion-content">
                <div
                  class="typo--h3 accordion-content"
                  v-html="shipping?.text"
                ></div>
              </template>
            </AccordionItem>
          </AccordionWrapper>
          <AccordionWrapper
            v-if="contacts?.title && contacts?.text"
          >
            <AccordionItem>
              <template slot="accordion-trigger">
                <div class="trigger">
                  <div class="accordion-title typo--h3">
                    {{ contacts?.title }}
                  </div>
                  <Icon name="plus" class="plus" />
                  <Icon name="minus" class="minus" />
                </div>
              </template>
              <template slot="accordion-content">
                <div
                  class="typo--h3 accordion-content"
                  v-html="contacts?.text"
                ></div>
              </template>
            </AccordionItem>
          </AccordionWrapper>
        </div>
        <div class="cart-notes">
          <div class="cart-notes-checkbox" @click="setCartNoteVisible">
            <div :class="['checkbox', { active: cartNoteVisibile }]" />
            <p class="typo--h3">{{ $labels().cart_note }}</p>
          </div>
          <textarea
            class="typo--h3"
            @input="setCartNote()"
            :placeholder="$labels().cart_note_placeholder"
            v-model="cartNote"
            v-show="cartNoteVisibile"
          ></textarea>
        </div>
        <a
          :href="cart?.checkoutUrl"
          class="cart-checkout-button typo--h3--trimmed"
        >
          {{ this.$labels().checkout_cta }}
        </a>
      </div>
    </div>
    <AppFooter />
  </main>
</template>

<script>
import ThumbProduct from "@/components/thumb/product";
import Link from "@/components/typo/link";
import CartList from "@/components/wc/cart-list";
import CartTotals from "@/components/wc/cart-totals";
import { mapGetters } from "vuex";
import Price from "@/components/shopify/price.vue";
import AccordionWrapper from "@/components/ui/accordion-wrapper";
import AccordionItem from "@/components/ui/accordion-item";
import Icon from "@/components/ui/icon";
import AppFooter from "@/components/ui/footer";

export default {
  name: "Cart",
  components: {
    ThumbProduct,
    Link,
    CartList,
    CartTotals,
    Price,
    AccordionWrapper,
    AccordionItem,
    Icon,
    AppFooter,
  },
  data() {
    return {
      cartNote: null,
      cartNoteVisibile: false,
    };
  },
  computed: {
    ...mapGetters(["cart", "options", "lang"]),
    lines() {
      return this.cart ? this.cart.lines.nodes : [];
    },
    shipping() {
      const { shipping, 'shipping-de': shippingDe } = this.options;
      return this.lang === 'de' ? shippingDe : shipping;
    },
    payments() {
      const { payments, 'payments-de': paymentsDe } = this.options;
      return this.lang === 'de' ? paymentsDe : payments;
    },
    contacts() {
      const { contacts, 'contacts-de': contactsDe } = this.options;
      return this.lang === 'de' ? contactsDe : contacts;
    },
  },
  methods: {
    async updateItem(id, quantity) {
      await this.$store.dispatch("updateCartLine", { id, quantity });
    },
    async removeItem(id) {
      await this.$store.dispatch("removeCartLine", id);
    },
    async setCartNote() {
      await this.$store.dispatch("setCartNote", this.cartNote);
    },
    setCartNoteVisible() {
      this.cartNoteVisibile = !this.cartNoteVisibile;
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  display: grid;
  row-gap: var(--row-gap-gutenberg);
  padding: var(--spacer-page);
  margin-top: var(--spacer-page-top);

  .cart-empty {
    grid-column: span 1;

    @include mq(m) {
      grid-column: span 4;
      height: calc(
        100vh - var(--spacer-page-top) - var(--spacer-page-bottom) -
          (2 * var(--spacer-xs))
      );
    }
  }

  .cart-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: var(--column-gap-xs);
    @include mq(m) {
      grid-template-columns: repeat(12, 1fr);
    }

    .title {
      grid-column: span 1;
      text-transform: uppercase;
      margin-bottom: var(--spacer-xs);

      @include mq(m) {
        grid-column: span 2;
      }
    }

    .cart-lines {
      grid-column: span 1;
      display: flex;
      flex-direction: column;
      gap: var(--column-gap-xs);
      margin-bottom: var(--spacer-l);

      @include mq(m) {
        grid-column: span 4;
      }
    }

    .space-block {
      grid-column: span 1;

      @include mq(m) {
        grid-column: span 2;
      }
    }

    .cart-totals {
      display: flex;
      flex-direction: column;
      grid-column: span 1;

      @include mq(m) {
        position: sticky;
        top: calc(var(--spacer-page-top) + var(--spacer-xs));
        grid-column: span 4;
        height: calc(
          100vh - var(--spacer-page-top) - var(--spacer-page-bottom) -
            (2 * var(--spacer-xs))
        );
      }
    }

    .cart-subtotal,
    .cart-shipping {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      order: 1;

      @include mq(m) {
        order: 0;
      }

      .t-right {
        text-align: right;
      }
    }

    .cart-information {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-m);
      margin-bottom: var(--spacer-xxl);

      @include mq(m) {
        margin-top: var(--spacer-l2);
        margin-bottom: 0;
        gap: 0;
      }
    }

    .trigger {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: var(--spacer-xs);
      cursor: pointer;

      .t-uppercase {
        text-transform: uppercase;
      }

      :deep(.icon) {
        pointer-events: none;
      }
    }

    .accordion-title {
      text-transform: uppercase;
    }
    .accordion__trigger {
      .minus {
        display: none;
      }

      .plus {
        display: grid;
      }
      &--active {
        .minus {
          display: grid;
        }

        .plus {
          display: none;
        }
      }
    }

    .accordion-content {
      margin-bottom: var(--spacer-l2);
    }

    .cart-notes {
      margin-bottom: var(--spacer-l2);
      order: -1;

      @include mq(m) {
        order: 0;
        margin-top: auto;
        margin-bottom: 0;
        margin-bottom: var(--spacer-xs);
      }

      .cart-notes-checkbox {
        display: flex;
        flex-direction: row;
        gap: var(--spacer-xs);
        align-items: center;
        cursor: pointer;
      }

      .checkbox {
        height: 10px;
        width: 10px;
        border: 1px solid var(--grey);

        &.active {
          background-color: var(--grey);
        }
      }

      textarea {
        width: 100%;
        height: 100px;
        padding: var(--spacer-s);
        margin-top: var(--spacer-xs);
        border: 1px solid var(--grey);
        resize: none;

        &::placeholder {
          @extend %typo--h3;
          color: var(--grey);
        }

        &:focus-visible {
          outline-color: var(--grey);
        }
      }
    }

    .cart-checkout-button {
      text-align: left;
      background-color: var(--orange);
      padding: var(--spacer-s);
      width: 100%;
      text-decoration: none;
      order: 2;
      margin-top: var(--spacer-l);

      @include mq(m) {
        order: 0;
        margin: 0;

        @media (hover: hover) {
          &:hover {
            background-color: var(--black);
            color: var(--white);
          }
        }
      }
    }
  }
}
</style>
