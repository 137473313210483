import GDSAxiosShopify from "@/api/axios-shopify";
import QueryProduct from "@/api/queries/shopify/product";
import QueryCollection from "@/api/queries/shopify/collection";
import QueryRecommendations from "@/api/queries/shopify/recommendations";
import QueryCart from "@/api/queries/shopify/cart";
import QueryMenu from "@/api/queries/shopify/menu";
import QueryMetaobjects from "@/api/queries/shopify/metaobjects"; 
import {
  add,
  create,
  remove,
  update,
  updateNote,
} from "@/api/queries/shopify/mutations/cart";

export const fetchSingle = ({ params = {} }) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: QueryProduct,
    variables: {
      handle: params.slug,
      lang: params.lang.toUpperCase(),
    },
  });
};

export const fetchCollection = ({ params = {} }) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: QueryCollection,
    variables: {
      handle: params.handle,
      first: params.first,
      after: params.after,
      sortKey: params.sortKey,
      lang: params.lang.toUpperCase(),
    },
  });
};

export const fetchMetaobjects = ({ params = {} }) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: QueryMetaobjects,
    variables: {
      type: params.type,
    },
  });
}

export const fetchMenu = ({ params = {} }) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: QueryMenu,
    variables: {
      handle: params.handle,
      lang: params.lang.toUpperCase(),
    },
  });
}

export const fetchProductRecommendations = ({ params = {} }) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: QueryRecommendations,
    variables: {
      productId: params.productId,
      intent: params.intent,
      lang: params.lang.toUpperCase(),
    },
  });
}

export const fetchCart = ({ params = {} }) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: QueryCart,
    variables: {
      cartId: params.cartId,
      lang: params.lang.toUpperCase(),
    },
  });
};

export const fetchAddToCart = (variables) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: add,
    variables,
  });
};

export const fetchCreateCart = (variables) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: create,
    variables,
  });
};

export const fetchUpdateCartLine = (variables) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: update,
    variables,
  });
};

export const fetchRemoveCartLine = (variables) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: remove,
    variables,
  });
};

export const fetchSetCartNote = (variables) => {
  return new GDSAxiosShopify().ajax.post("", {
    query: updateNote,
    variables,
  });
};