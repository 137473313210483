import Cookies from "js-cookie";

export default {
  cart: (state) => state.cart,
  optionColors: (state) => state.optionColors,
  items: (state) => {
    if (state.cart && state.cart.lineItems) {
      return state.cart.lineItems.nodes;
    } else {
      return [];
    }
  },
  itemInCart: (state) => (itemId) =>
    state.cart.items.find((item) => item.merchandise.id === itemId),

  idFromCookie: (state) => {
    const cartIdFromCookie = Cookies.get("flaviatschanz-cart");
    return state.cart?.id ? state.cart.id : cartIdFromCookie;
  },
  totalPrice: (state) => state.cart.cost.subtotalAmount,
};
