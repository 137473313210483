/* eslint-disable no-undef */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Page from "@/views/Page.vue";
import Search from "@/views/Search.vue";

import Collection from "@/views/Collection.vue";
import NotFound from "@/views/404.vue";
import Preview from "@/views/Preview.vue";
// import wcRoutes from './wc';
import shopifyRoutes from "./shopify";

// const importedRoutes = wcRoutes;
const importedRoutes = shopifyRoutes;

const singles = new Map();

// HINT: Add custom Post Type - 2
singles.set("collection", Collection);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const { postTypes, i18n } = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

let langs = null;

if (i18n) {
  store.commit("ADD_LANGUAGES", Object.keys(i18n.langs));

  langs = Object.keys(i18n.langs).filter((lang) => lang !== i18n.default);
  langs = langs.join("|");
} else {
  store.commit("ADD_LANGUAGES", ["default"]);
}

const baseRoutes = [
  {
    path: langs ? `/:lang(${langs})?/404` : "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: langs ? `/:lang(${langs})?/` : "/",
    name: "Home",
    component: Page,
    meta: {
      slug: page_on_front,
      type: show_on_front ? "pages" : "posts",
    },
  },
  {
    path: langs ? `/:lang(${langs})?/search` : "/search",
    name: "Search",
    component: Search,
  },
  {
    path: langs ? `/:lang(${langs})?/:slug` : "/:slug",
    name: "Page",
    component: Page,
  },
  {
    path: langs ? `/:lang(${langs})?/preview/:type/:id` : "/preview/:type/:id",
    name: "Preview",
    component: Preview,
  },
];

const routes = importedRoutes.concat(baseRoutes);

const ignoredPostTypes = ["post", "page", "product", "news", "wps-product"];

Object.keys(postTypes).forEach((key) => {
  if (!ignoredPostTypes.includes(key)) {
    routes.push({
      path: langs
        ? `/:lang(${langs})?/${postTypes[key].rewrite.slug}/:slug`
        : `/${postTypes[key].rewrite.slug}/:slug`,
      name: singles.get(key).name,
      component: singles.get(key),
      meta: {
        type: postTypes[key].rest_base,
      },
    });
  }
});

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : "/",
  routes,
  scrollBehavior(to, from) {
    if (from.path !== to.path) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 });
        }, 400);
      });
    }
    return false;
  },
});

router.beforeEach(async (to, from, next) => {
  if (document.body.classList.contains("app-loaded")) {
    document.body.classList.remove("app-loaded");
  }
  store.commit("SET_TRANSITION", false);

  if (from.path !== to.path) {
    store.commit("SET_FROM", from.name);
    store.commit("SET_CURRENT_POST", null);
  }

  if (to.meta.customView || to.name === "404" || to.name === "Search" || to.name === "Preview") {
    document.title = `${to.name} — ${siteTitle}`;
    if (!document.body.classList.contains("app-loaded")) {
      document.body.classList.add("app-loaded");
    }
    store.commit("SET_TRANSITION", true);
    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = to.params.lang ? to.params.lang : i18n ? i18n.default : "default";

  store.commit("SET_LANG", lang);

  const request = {
    type: to.meta.type || "pages",
    slug,
    lang,
  };

  const promises = [];

  promises.push(store.dispatch("getSingleBySlug", request));

  // console.log(request);

  // if (request.slug === 'products') {
  //   promises.push(store.dispatch('getItems', {
  //     type: 'product',
  //     params: {
  //       category: 40,
  //       per_page: 100,
  //       offset: 0,
  //       order: 'desc',
  //       orderby: 'date',
  //       lang: store.state.lang,
  //     },
  //   }));
  // }

  Promise.all(promises).then(async (res) => {
    const page = res[0];

    if (page && slug) {
      if (to.path === "/") {
        document.title = `${siteTitle} ${description ? ` — ${description}` : ""}`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement("p");
        p.innerHTML = page.title ? page.title.rendered : page.name;
        document.title = `${p.innerText} — ${siteTitle}`;
      }

      if (i18n) {
        const { default_locale } = i18n.langs[lang];
        document.documentElement.lang = default_locale.replace("_", "-");
      }

      const blocks = page.gds_blocks || [];
      const groupBlocks = [];
      const archives = [];
      const thumbs = [];

      blocks.forEach((block) => {
        if (block.blockName === "core/group") {
          groupBlocks.push(...block.innerBlocks);
        } else if (block.blockName === "acf/archive") {
          archives.push(block);
        } else if (block.blockName === "acf/product-preview") {
          thumbs.push(block);
        }
      });

      groupBlocks.forEach((block) => {
        if (block.blockName === "acf/archive") {
          archives.push(block);
        } else if (block.blockName === "acf/product-preview") {
          thumbs.push(block);
        }
      });

      const thumbsPromises = [];
      if (thumbs.length > 0) {
        thumbs.forEach((thumb) => {
          const { value } = thumb.attrs.data;
          const request = {
            type: "wps-product",
            slug: value.product.post_name,
            lang: store.state.lang,
          };
          thumbsPromises.push(store.dispatch("getSingleBySlug", request));
        });
      }
      const archivePromises = [];
      if (archives.length > 0) {
        archives.forEach((archive) => {
          const { settings } = archive.attrs.data;
          const filters = {};

          settings.filters.forEach((filter) => {
            const string = filter.split(":");
            // eslint-disable-next-line prefer-destructuring
            filters[string[0]] = string[1];
          });

          const archiveRequest = {
            params: {
              ...filters,
              first: 20,
              // per_page: parseInt(settings.posts_per_page, 10),
              // offset: 0,
              // order: settings.order ? "desc" : "asc",
              // orderby: settings.order_by,
              lang: store.state.lang,
            },
          };

          archivePromises.push(store.dispatch("getCollection", archiveRequest));
        });
      }

      if (thumbsPromises.length > 0 || archivePromises.length > 0) {
        await Promise.all(archivePromises);
        const thumbsRes = await Promise.all(thumbsPromises);
        thumbs.map((thumb, index) => {
          thumb.attrs.data.value.product.shopify = thumbsRes[index].shopify;
          return thumb;
        });

        if (!document.body.classList.contains("app-loaded")) {
          document.body.classList.add("app-loaded");
        }
        store.commit("SET_TRANSITION", true);
        next();
      } else {
        if (!document.body.classList.contains("app-loaded")) {
          document.body.classList.add("app-loaded");
        }
        store.commit("SET_TRANSITION", true);
        next();
      }
    } else {
      router.push({
        name: "404",
        params: {
          lang: lang !== i18n.default ? lang : undefined,
        },
      });
    }
  });
});

export default router;
