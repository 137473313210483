import Cookies from "js-cookie";

export default {
  // SET_CART_LOADING(state, value) {
  //   if (value) {
  //     document.querySelector("html").classList.add("wc-cart-loading");
  //   } else {
  //     document.querySelector("html").classList.remove("wc-cart-loading");
  //   }
  //   state.cartLoading = value;
  // },
  SET_CART(state, response) {
    state.cart = response;
  },
  SET_CART_COOKIE(state, cartId) {
    Cookies.set("flaviatschanz-cart", cartId, {
      expires: 1,
    });
  },
  SET_OPTION_COLORS(state, response) {
    state.optionColors = response;
  }
};
