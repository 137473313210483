import product from "@/api/queries/shopify/fragments/product";

export default /* GraphQL */ `
  ${product}
  query (
    $handle: String!
    $first: Int
    $after: String
    $sortKey: ProductCollectionSortKeys
    $reverse: Boolean
    $lang: LanguageCode!
  ) @inContext(language: $lang) {
    collection(handle: $handle) {
      id
      title
      descriptionHtml
      seo {
        title
        description
      }
      products(
        first: $first
        after: $after
        reverse: $reverse
        sortKey: $sortKey
      ) {
        nodes {
          ...Product
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      sizeguide: metafield(namespace: "custom", key: "sizeguide") {
        value
      }
      sizeguide_description: metafield(namespace: "custom", key: "sizeguide_description") {
      value
    }
    }
  }
`;

export const collectionThumb = /* GraphQL */ `
  query ($handle: String!, $lang: LanguageCode!) @inContext(language: $lang) {
    collection(handle: $handle) {
      id
      title
      descriptionHtml
    }
  }
`;
