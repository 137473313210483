var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:['menu', _vm.theme ? `menu--${_vm.theme}` : false]},[_c('div',{staticClass:"menu-row"},_vm._l((_vm.navigation),function(item){return _c('div',{key:item.id,staticClass:"nav-link"},[_c('Link',{attrs:{"theme":"plain","data":{
          url: item.url,
          title: item.content,
          target: item.target,
        }}}),((item.content === 'Cart' || item.content === 'Warenkorb') && _vm.cartQuantity > 0)?_c('div',{staticClass:"cart-quantity typo--p--trimmed"},[_vm._v(" ("+_vm._s(_vm.cartQuantity)+") ")]):_vm._e()],1)}),0),_c('div',{staticClass:"menu-row"},_vm._l((_vm.navigationRow2),function(item){return _c('div',{key:item.id,staticClass:"nav-link"},[_c('Link',{attrs:{"theme":"plain","data":{
          url: item.url,
          title: item.content,
          target: item.target,
        }}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }