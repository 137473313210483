import product from "@/api/queries/shopify/fragments/product";

export default /* GraphQL */ `
  ${product}
  query ($productId: ID!, $intent: ProductRecommendationIntent!, $lang: LanguageCode!)
  @inContext(language: $lang) {
    productRecommendations(productId: $productId, intent: $intent) {
      ...Product
    }
  }
`;
