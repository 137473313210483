import cart from "../shopify/fragments/cart";

export default /* GraphQL */ `
  ${cart}
  query ($cartId: ID!, $lang: LanguageCode!) @inContext(language: $lang) {
    cart(id: $cartId) {
      ...Cart
    }
  }
`;
