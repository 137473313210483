var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('Flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:['modal', `modal-theme--${_vm.theme}`, 'overlay'],attrs:{"align":"center","tabindex":_vm.show ? 0 : -1}},[(_vm.data)?_c('Wrapper',{class:['modal-wrapper'],attrs:{"size":"m","boxed":""}},[(!_vm.data.mandatory)?_c('Icon',{staticClass:"icon--close",attrs:{"name":"close","fn":_vm.close,"theme":"minimal","size":"m"}}):_vm._e(),(_vm.data.type === 'content')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Title',{attrs:{"value":_vm.data.content.title}}),_c('Richtext',{attrs:{"value":_vm.data.content.richtext}}),_c('Flex',{attrs:{"justify":"center"}},[_c('Link',{attrs:{"data":_vm.data.content.cta.title,"theme":"button"}})],1)],1)]:_vm._e(),(_vm.data.type === 'dialog')?[_c('Spacer',{attrs:{"all":"m"}},[_c('Grid',{attrs:{"col":"12"}},[_c('Title',{attrs:{"value":_vm.data.content.title}}),_c('Richtext',{attrs:{"value":_vm.data.content.richtext}}),_c('Flex',{attrs:{"justify":"center"}},[_c('Link',{attrs:{"data":{
                  fn: _vm.dialogFn,
                  title: _vm.data.content.cta.title,
                },"theme":"button"}})],1)],1)],1)]:_vm._e(),(_vm.data.type === 'cart')?[_c('Grid',{staticClass:"modal-cart",attrs:{"col":"12"}},[_c('Title',{attrs:{"value":_vm.data.content.title}}),_c('Richtext',{attrs:{"value":_vm.data.content.richtext}}),_c('Flex',{attrs:{"justify":"start","column-gap":"xs","row-gap":"xs"}},[_c('Link',{attrs:{"data":{
                url: _vm.data.content.cta.url,
                title: _vm.data.content.cta.title,
              }}}),_c('span',{staticClass:"typo--p"},[_vm._v(_vm._s(_vm.$labels().addtocart_modal_or))]),_c('Link',{attrs:{"data":{
                url: _vm.data.content.cta_checkout.url,
                title: _vm.data.content.cta_checkout.title,
              }}})],1)],1)]:_vm._e()],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }