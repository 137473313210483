<template>
  <div :class="['thumb-product', `theme-${theme}`]" v-show="!placeholder">
    <router-link
      :to="$route.params?.lang ? `/${$route.params?.lang}/products/${productData?.handle}` : `/products/${productData?.handle}`"
      class="thumb-image-link"
    >
      <Figure
        :data="{
          value: {
            src: parsedData(productData?.image),
          },
          settings: {},
        }"
        :lazyload="false"
        :ratio="[2, 3]"
        class="thumb-image"
        v-if="productData?.image"
      />
    </router-link>

    <div class="thumb-info" v-if="theme === 'cart'">
      <div class="info-container">
        <div class="product-title typo--h3">{{ productData.title }}</div>
        <div
          class="variant-title typo--h3"
          v-if="productData.variantTitle !== 'Default Title'"
        >
          {{ productData.variantTitle }}
        </div>
        <div class="quantity-selector">
          <div class="minus" @click="updateQuantity(quantity - 1)">
            <Icon name="minus" />
          </div>
          <div class="typo--h3">{{ quantity }}</div>
          <div class="plus" @click="updateQuantity(quantity + 1)">
            <Icon name="plus" />
          </div>
        </div>
      </div>
      <Price
        :price="productData?.price"
        :compareAtPrice="productData?.compareAtPrice"
        class="product-price"
      ></Price>
    </div>
    <div v-else class="thumb-info">
      <div class="info-container">
        <div class="product-title typo--h3">{{ productData.title }}</div>
        <Price
          :priceRange="productData?.priceRange"
          :compareAtPriceRange="productData?.compareAtPriceRange"
          class="product-price"
          :theme="theme === 'collection' ? 'inline' : ''"
        ></Price>
      </div>
    </div>
  </div>
</template>

<script>
import Figure from "@/components/media/figure";
import Price from "@/components/shopify/price.vue";
import Icon from "@/components/ui/icon";

export default {
  name: "ProductThumb",
  components: {
    Figure,
    Price,
    Icon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      default: 1,
    },
    placeholder: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
  },
  methods: {
    parsedData(data) {
      const image = {
        src: data.url,
        sizes: {
          xl: data.url,
          l: data.url,
          m: data.url,
          s: data.url,
          "xl-width": data.width,
          "xl-height": data.height,
          "l-width": data.width,
          "l-height": data.height,
          "m-width": data.width,
          "m-height": data.height,
          "s-width": data.width,
          "s-height": data.height,
        },
        width: data.width,
        height: data.height,
      };
      return image;
    },
    updateQuantity(newQuantity) {
      if (newQuantity > 0) {
        this.$emit("update", newQuantity);
      } else {
        this.$emit("remove");
      }
    },
  },
  computed: {
    description() {
      let description = "";

      if (this.data.acf && this.data.acf.description !== "") {
        description = this.data.acf.description;
      }

      if (this.data.description && this.data.description !== "") {
        description = this.data.description;
      }

      return description;
    },
    productData() {
      return {
        title:
          this.theme === "cart"
            ? this.data.variant.product.title
            : this.data.product.title,
        variantTitle: this.theme === "cart" ? this.data.variant.title : null,
        price:
          this.theme === "cart"
            ? this.data.variant.price
            : this.data.product.price,
        compareAtPrice:
          this.theme === "cart"
            ? this.data.variant.compareAtPrice
            : this.data.product.compareAtPrice,
        handle:
          this.theme === "cart"
            ? this.data.variant.product.handle
            : this.data.product.handle,
        image:
          this.theme === "cart"
            ? this.data.variant.image
            : this.data.product.featuredImage,
        priceRange: this.theme === "cart" ? null : this.data.product.priceRange,
        compareAtPriceRange:
          this.theme === "cart" ? null : this.data.product.compareAtPriceRange,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb-product {
  &.theme-collection {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: var(--column-gap-xs);
    .product-title {
      margin-bottom: var(--spacer-m);
    }

    .product-price {
      margin-bottom: var(--spacer-xs);
    }
  }

  &.theme-cart {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: var(--column-gap-xs);
    text-decoration: none;

    @include mq(m) {
      grid-template-columns: repeat(4, 1fr);
    }

    .thumb-image-link {
      grid-column: span 3;
      cursor: pointer;
      @include mq(m) {
        grid-column: span 1;
      }
    }

    .thumb-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      grid-column: span 5;

      @include mq(m) {
        grid-column: span 3;
      }
      .variant-title {
        @include mq(m) {
          font-style: italic;
        }
      }

      .product-price {
        text-align: right;
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;
      gap: var(--spacer-l);
      align-items: flex-start;
    }

    .quantity-selector {
      display: flex;
      flex-direction: row;
      gap: var(--spacer-xs);
      align-items: center;

      .minus,
      .plus {
        cursor: pointer;
        padding: var(--spacer-xs);

        :deep(.icon) {
          pointer-events: none;
        }

        @include mq(m) {
          @media (hover: hover) {
            &:hover {
              :deep(.icon svg) {
                stroke: var(--orange);
              }
            }
          }
        }
      }
    }
  }
}
</style>
