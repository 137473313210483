var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Grid',{attrs:{"col":"12","row-gap":"s"}},_vm._l((_vm.cart.items),function(cartItem,index){return _c('div',{key:index,attrs:{"data-key":cartItem.item_key,"data-quantity":cartItem.quantity.value}},[_c('Flex',{attrs:{"justify":"space-between"}},[_c('Flex',{staticClass:"flex-no-wrap cart__item",attrs:{"column-gap":"m"}},[_c('div',{staticClass:"wc-product-image wc-product-image--l",style:({
            backgroundImage: `url(${cartItem.featured_image})`,
          })}),_c('Flex',{attrs:{"dir":"column","justify":"space-between"}},[_c('router-link',{attrs:{"to":{
              name: 'SingleProduct',
              params: {
                slug: cartItem.slug,
              },
            }}},[_vm._v(" "+_vm._s(cartItem.name)+" "),(cartItem.cart_item_data)?_c('Spacer',{attrs:{"y":"xs"}},_vm._l((cartItem.cart_item_data),function(itemData,j){return _c('div',{key:j,staticClass:"typo--xs"},[_vm._v(" "+_vm._s(itemData)+" ")])}),0):_vm._e()],1),(_vm.theme !== 'checkout')?_c('Flex',{attrs:{"align":"center"}},[(cartItem.cart_item_data == null || cartItem.cart_item_data.length === 0)?_c('Number',{key:_vm.cartLoading,attrs:{"data":{
                id: `number`,
                fn: _vm.numberChanged,
                itemKey: cartItem.item_key,
                readonly: _vm.cartLoading
              },"value":cartItem.quantity.value}}):_vm._e(),_c('Spacer',{attrs:{"left":(cartItem.cart_item_data == null || cartItem.cart_item_data.length === 0) ? 's' : false}},[_c('a',{staticClass:"button cart__remove-item typo--s",on:{"click":function($event){return _vm.removeCartItem(cartItem)}}},[_vm._v(" "+_vm._s(_vm.$labels.remove)+" ")])])],1):_vm._e()],1)],1),(cartItem.is_deposit)?_c('div',{domProps:{"innerHTML":_vm._s(cartItem.deposit_item_subtotal)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.getLineTotal(cartItem))}})],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }