import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import wp from "./modules/wp";
import auth from "./modules/auth";
import shopify from "./modules/shopify";

// eslint-disable-next-line no-undef
const { state } = __VUE_WORDPRESS__;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentPost: null,
    lang: state.i18n ? state.i18n.default : "default",
    transition: true,
    snackbar: {
      content: null,
      active: false,
    },
    from: null,
  },
  mutations,
  getters,
  actions,
  modules: {
    wp,
    auth,
    shopify,
  },
});
