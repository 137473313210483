import axios from "axios";

let instance = null;
class GDSAxiosShopify {
  constructor() {
    if (instance) return instance;

    // eslint-disable-next-line no-undef
    this.ajax = axios.create({
      baseURL: process.env.VUE_APP_SHOPIFY_API,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": process.env.VUE_APP_SHOPIFY_STOREFRONT_TOKEN,
      },
    });

    instance = this;
    return instance;
  }
}

export default GDSAxiosShopify;
