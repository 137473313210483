import variant from "./variant";

export default /* GraphQL */ `
  ${variant}
  fragment Product on Product {
    id
    handle
    title
    tags
    availableForSale
    seo {
      title
      description
    }
    description
    descriptionHtml
    productType
    totalInventory
    options {
      id
      name
      values
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    featuredImage {
      url
      altText
      width
      height
    }
    images(first: 10) {
      nodes {
        url
        altText
        width
        height
        id
      }
    }
    media(first: 10) {
      nodes {
        mediaContentType
        ... on MediaImage {
          id
          mediaContentType
          image {
            width
            url
            id
            height
            altText
            src
          }
        }
        ... on Video {
          id
          mediaContentType
          sources {
            format
            height
            url
            width
          }
          previewImage {
            height
            id
            src
            width
          }
        }
      }
    }
    collections(first: 10) {
      nodes {
        id
        handle
        title
        sizeguide: metafield(namespace: "custom", key: "sizeguide") {
          value
        }
        sizeguide_description: metafield(
          namespace: "custom"
          key: "sizeguide_description"
        ) {
          value
        }
      }
    }
    variants(first: 100) {
      nodes {
        id
        ...Variant
      }
    }
    options_with_color: metafield(
      namespace: "custom"
      key: "options_with_color"
    ) {
      value
    }
    sizeguide: metafield(namespace: "custom", key: "sizeguide") {
      value
    }
    sizeguide_description: metafield(
      namespace: "custom"
      key: "sizeguide_description"
    ) {
      value
    }
  }
`;
